<template>

  <div class="card friend-card" @click="showModal">
    <div class="card-body">
      <div class="d-flex">
        <div class="p-2 w-100">
          <h4>
<!--            <ProfilePicture :user="friend" size="50"></ProfilePicture> <ProfileName :user="friend"></ProfileName>-->
            <img :src="publicPath + 'friend-pic-default.png'" :height=50 class="profile-pic" referrerPolicy="no-referrer">
            <img :src="publicPath + 'plus.png'" :height=20 class="plus-sign" referrerPolicy="no-referrer">
            <span class="invite-text">invite a friend</span>
          </h4>
        </div>
      </div>
    </div>

  </div>

  <GenericModal v-if="show_code_modal" @modal-closed="show_code_modal=false">
    <template #header>
      <h6>share link to add friend</h6>
    </template>

    <div style="text-align: center">
      <QRCodeVue3 :value="link"
                  :width="250" :height="250"
                  :image="logo"
                  :imageOptions="{margin: 2, imageSize: 0.25}"
                  :dotsOptions="{type: 'extra-rounded'}"
                  :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
      />
      <div class="d-grid gap-2">
        <button class="btn btn-sm btn-block btn-outline-success" type="button" @click="copyLinkToClipboard">
          <span v-if="!textCopied">copy invite link<br><span style="opacity: 50%;">{{link}}</span></span>
          <span v-else>copied!</span>
        </button>
      </div>
    </div>

  </GenericModal>



</template>


<script>


import GenericModal from "@/components/modal/GenericModal.vue";
import QRCodeVue3 from "qrcode-vue3";
import {userStore} from "@/userStore";
import analytics from "@/analytics";

export default {
  name: 'FriendCard_ShowCode',
   components: {QRCodeVue3, GenericModal},
  computed: {
    link() {
      return `${location.origin}/connect-with/${userStore.getFriendConnectionId()}`;
    },
    logo() {
      return process.env.BASE_URL + 'logo-64.png';
    }
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      show_code_modal: false,
      textCopied: false
    }
  },
  methods: {
    showModal() {
      this.show_code_modal = true;
      this.textCopied = false;
      // this.$gtag.event('friend_link_qr_code_viewed');
      analytics.sendPage('Friend Code');
    },
    copyLinkToClipboard() {
      navigator.clipboard.writeText( this.link );
      this.textCopied = true;
      // this.$gtag.event('friend_link_copied');
      analytics.sendEvent('Friend Code Copied');
    }
  }
}
</script>


<style scoped>

.profile-films-viewed-graphic {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.friend-card {
  isolation: isolate;
  overflow: hidden;

  text-decoration: inherit;

  border-style: double;
  border-width: 4px;
  background-color: aliceblue;
  border-color: cornflowerblue;
  color: cornflowerblue;
  margin: 18px;

  &:hover {
    box-shadow: 0 0 0 1px cornflowerblue;
  }

}

.invite-text {
  margin-left: -15px;
}

.profile-pic {
  position: relative;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1px;
  border-color: lightgrey;
}
.plus-sign {
  position: relative;
  left: -15px;
  bottom: -15px;
}




</style>