<template>
  <ExpandableCard @show-more="onCardExpanded()" class="expandable-card text-bg-light">
    <template #header>
      <span class="card-title">
        <i class="fa fa-ticket"></i> Local Theaters
      </span>
      <span v-show="filtersApplied" class="filters-applied-note" >
        <i class="fa fa-filter"></i> filters applied
      </span>

      <!-- Slot is used for the header, generally an image with this provider's logo. -->
      <slot></slot>

    </template>

    <template #body>

      <section class="expandable-card-body">

        <!--      if geolocation permission has not been granted...  -->
        <div v-if="geolocationAccess !== 'granted'" class="no-location-data">
          <div v-if="geolocationAccess === 'unsupported'">
            <i class="fa fa-compass"></i> location detection is not supported in this browser
          </div>

          <div v-if="geolocationAccess === 'denied'">
            <i class="fa fa-compass"></i> enable location detection in your browser and refresh to search local theater showings
          </div>

          <div v-if="geolocationAccess === 'prompt'">
            <div class="btn btn-outline-secondary btn-sm" type="button" @click="populateFilmTheaterShowingsFromGeolocation()">
              <i class="fa fa-compass"></i> search for local theaters
            </div>
          </div>

          <div v-if="geolocationAccess === 'position unavailable'">
            local position unavailable<br>try searching again
            <div style="margin-top: 1em;" class="btn btn-outline-secondary btn-sm" type="button" @click="populateFilmTheaterShowingsFromGeolocation()">
              <i class="fa fa-compass"></i> search for local theaters
            </div>
          </div>

          <div v-if="geolocationAccess === 'timeout'">
            search timed out<br>try searching again
            <div style="margin-top: 1em;" class="btn btn-outline-secondary btn-sm" type="button" @click="populateFilmTheaterShowingsFromGeolocation()">
              <i class="fa fa-compass"></i> search for local theaters
            </div>
          </div>
        </div>

        <!--      if geolocation permission HAS been granted...  -->
        <div v-else>
          <LoadingSpinner v-if="waitingForResponse" class="text-secondary" style="margin-top: 10px;"></LoadingSpinner>

          <div v-else v-for="(theater) in theaters" :key="theater">
            <div v-if="filmsShowingsForTheater(theater).length > 0" class="theater-name">{{theater.toLowerCase()}}</div>
            <div v-for="(filmShowing) in filmsShowingsForTheater(theater)" :key="filmShowing.film._id" class="container">
              <FilmLink
                  :film_name="filmShowing.film.name"
                  :film_id="filmShowing.film._id"
                  :show_if_bookmarked="true"
                  :show_flag_for_language_code="filmShowing.film.original_language !== 'en' ? filmShowing.film.original_language : null"
                  single_line="true"
              >
              </FilmLink>
            </div>
          </div>
        </div>
      </section>
    </template>

    <template #footer>
      <div v-if="geolocationAccess === 'granted'">
        <div v-if="location.zipcode" class="btn btn-sm btn-zipcode" type="button" @click="changeZipcode">
          zip {{location.zipcode}}
        </div>
      </div>
    </template>
  </ExpandableCard>
</template>


<script>
import axios from "axios";

import { userStore } from "@/userStore";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import FilmLink from "@/components/FilmLink.vue";
import ExpandableCard from "@/components/ExpandableCard.vue";
import analytics from "@/analytics";

export default {
  name: "LocalTheaterFilmListCard",
  components: {FilmLink, LoadingSpinner, ExpandableCard},
  props: ['hideViewedFilms', 'filterByGenres'],
  data() {
    return {
      location: {zipcode: null, lat: null, long: null},
      geolocationAccess: null, // 'granted', 'denied', 'prompt' or 'unsupported', 'position unavailable' or 'timeout'
      showGeolocationDeniedMessage: false,
      filmTheaterShowings: [],
      waitingForResponse: false,
      showExpanded: false,
      expandedIndex: 8,
      userViewedFilmsSnapshot: []
    }
  },
  computed: {
    theaters() {
      let theaters = [];
      this.filmTheaterShowings.forEach((fts) => {
        fts.theaterShowings.forEach((ts) => {
          if ( !theaters.includes(ts.theater) ) {
            theaters.push(ts.theater);
          }
        });
      });
      return theaters;
    },
    filtersApplied() {
      return ( this.hideViewedFilms || ( this.filterByGenres && this.filterByGenres.length > 0 ) );
    }
  },
  watch: {
    hideViewedFilms(newValue) {
      if ( newValue === true ) {
        this.saveUserViewedFilmsSnapshot();
      }
    },
  },

  async created() {
    this.saveUserViewedFilmsSnapshot();
    this.initGeolocation();
  },

  methods: {

    async initGeolocation() {
      // get current geolocation access permission, and if it's granted, automatically fill in the theater showings
      return this.updateGeolocationAccess()
          .then(() => {
            if ( this.geolocationAccess === 'granted' ) {
              return this.populateFilmTheaterShowingsFromGeolocation();
            }
          });
    },

    async updateGeolocationAccess() {

      // check if this browser even supports geolocation
      if ( !navigator?.geolocation || !navigator?.permissions ) {
        this.geolocationAccess = 'unsupported';
        return this.geolocationAccess;
      }

      // "The Permissions API geolocation permission can be used to test whether access
      //   to use location information is 'granted', 'denied' or 'prompt'.
      return navigator.permissions.query({ name: 'geolocation' })
          .then((permissionStatus) => {
            this.geolocationAccess = permissionStatus?.state; // 'granted', 'denied' or 'prompt'
            //console.log(`Geolocation permission state: ${permissionStatus?.state}.`);
            return this.geolocationAccess;
          });
    },

    async getGeolocationPosition(options) {
      return new Promise(function (resolve, reject) {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
      });
    },

    async populateFilmTheaterShowingsFromGeolocation() {

      // clear our display schedule
      this.filmTheaterShowings = [];
      this.waitingForResponse = true;
      this.showGeolocationDeniedMessage = false;

      this.getGeolocationPosition()
          .then((position) => {
            //console.log(position);

            // if we got a position, access must be granted now even if it wasn't before
            this.geolocationAccess = 'granted';
            //this.geolocationAccess = 'position unavailable';

            return axios.get(`/api/viewing-options/theater-showings`, {
              params: {
                lat: position.coords.latitude,
                long: position.coords.longitude
              }
            })
          })

          .then((response) => {
            //console.log(`/api/viewing-options/streaming/${this.provider} => ${response}`);
            this.filmTheaterShowings = response?.data;
          })

          .catch((err) => {
            console.log(err);

            if (err.code == err.PERMISSION_DENIED) {
              this.geolocationAccess = 'denied';
            } else if (err.code == err.POSITION_UNAVAILABLE) {
              this.geolocationAccess = 'position unavailable'
            } else if (err.code == err.TIMEOUT) {
              this.geolocationAccess = 'timeout'
            }

          })


          .finally(() => {
            this.waitingForResponse = false;
          });
    },

    async populateFilmTheaterShowingsFromZipcode(_zipcode) {

      // clear our display schedule
      this.filmTheaterShowings = [];
      this.waitingForResponse = true;
      this.showGeolocationDeniedMessage = false;

      axios.get(`/api/viewing-options/theater-showings`, {
        params: {
          zipcode: _zipcode
        }
      })
          .then((response) => {
            //console.log(`/api/viewing-options/streaming/${this.provider} => ${response}`);
            this.filmTheaterShowings = response?.data;
          })

          .catch((err) => {
            console.log(err);
          })

          .finally(() => {
            this.waitingForResponse = false;
          });
    },

    saveUserViewedFilmsSnapshot() {
      // We cache the films a user has viewed. If viewed films are hidden, and something else (like a film modal) marks
      //  a film as viewed, we DON'T want the visibility to react--we just want it to show crossed-out.
      if ( userStore.user?.viewed ) {
        this.userViewedFilmsSnapshot = userStore.user?.viewed;
      }
    },

    filterFilmList(_filmList) {

      var filteredFilmList = _filmList;

      if ( this.hideViewedFilms ) {
        filteredFilmList = filteredFilmList.filter((film) => {
          return !this.userViewedFilmsSnapshot.includes(film._id);
        });
      }

      if ( this.filterByGenres && (this.filterByGenres.length > 0) ) {
        filteredFilmList = filteredFilmList.filter((film) => {
          return film.tmdb.genres.find((filmGenre) => {
            return this.filterByGenres.includes(filmGenre);
          });
        });
      }

      return filteredFilmList;
    },

    filmsShowingsForTheater(_theater) {
      let filmShowings = [];
      this.filmTheaterShowings.forEach((fts) => {

        // skip past viewed films if that filter is set
        if ( this.hideViewedFilms && this.userViewedFilmsSnapshot.includes(fts.film._id) ) {
          return; // continue iterating filmTheaterShowings
        }

        if ( this.filterByGenres && (this.filterByGenres.length > 0) ) {
          const foundGenreInFilm = fts.film.tmdb.genres.find((filmGenre) => {
            return this.filterByGenres.includes(filmGenre);
          });
          if ( !foundGenreInFilm ) {
            return; // continue iterating filmTheaterShowings
          }
        }

        const thisTheatersShowings = fts.theaterShowings.find((ts) => {
          return ( ts.theater === _theater );
        });

        if ( thisTheatersShowings ) {
          filmShowings.push({
            film: fts.film,
            showings: thisTheatersShowings.showings
          })
        }
      });
      return filmShowings;
    },
    onCardExpanded() {
      analytics.sendEvent('Viewing Option Explored', {
        provider: 'Local Theaters'
      });
    }
  }
}

</script>


<style scoped>

.expandable-card {
  margin-bottom: 20px;
}

.expandable-card-body {
  font-size: x-small;
  overflow-y: hidden;

  .language-icon {
    padding-left: 0.2em;
    opacity: 80%;
  }

  li {
    line-height: 105%;
    display: block;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 15px;
    padding-right: 0px;
    text-indent: -10px;
  }
}

.btn-zipcode {
  float: left;
  font-size: small;
  color: #1c1c1c;
  opacity: 80%;
}

.filters-applied-note {
  position: absolute;
  top: 40px;
  right: 1em;
  color: var(--bs-primary);
  opacity: 80%;
  font-size: 9px;
  margin-top: -4px;
}

.theater-name {
  font-size: small;
  font-weight: 700;
  color: var(--bs-secondary);
  padding-top: 5px;
  padding-bottom: 2px;
}

.card-title {
  font-family: "Limelight", serif;
  color: #1c1c1c;
  font-size: 18px;
  height: 20px;
}

.no-location-data {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  text-align: center;
  font-size: small;
  font-weight: 700;
  color: var(--bs-secondary);
  padding-top: 5px;
  padding-bottom: 2px;

}
</style>