<template>
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
    <g id="laurel">
      <g>
        <path :fill="color" d="M85.5,19.4c-4.2,8.4-9.5,16.7-15.4,24-5,6.2-12.8,14.8-19,19.8-.2.2-1,.8-1.2.7-2.5-6.2-2.9-13.1-1.1-19.6,4.3-15.2,21.5-25,36.7-24.9Z"/>
        <path :fill="color" d="M192,56.2c-.2.2-3.2-1.7-3.7-2-14.2-9-26.5-18.5-36-32.6-.3-.4-2.1-2.9-1.8-3.2,13.5-1.1,28.4,3.5,36.5,14.8,4.7,6.5,6.6,15.1,5,23Z"/>
      </g>
      <g>
        <g>
          <path :fill="color" d="M217.7,104c-1.4-4.4-2.5-9-3.1-13.6-1.6-12.5.5-26.6,3.4-38.8.1-.6.6-3.3,1.2-3.2,9.6,9.8,15.5,24.1,11.5,37.7-1.5,5-4.7,10.2-8.3,14-.5.5-4.2,4-4.6,3.8l-.1.1Z"/>
          <path :fill="color" d="M214.1,115.7c-.4.4-9.2-3.7-10.3-4.2-7-3.5-14-8.2-18.5-14.7-1.7-2.5-3.5-5.4-4.2-8.4,8.2-1.7,16.7-1,23.4,4.4,5.1,4.1,8.4,10.9,9.3,17.3.1.8.6,5.3.2,5.6h.1Z"/>
        </g>
        <g>
          <g>
            <path :fill="color" d="M244,112.5c1.8,8.5,1.3,17.4-1.8,25.5-4.1,10.8-13.3,18.1-24.5,20.7-.5-.6,1.7-10.3,2-11.7,3.4-13.3,9.2-22.2,19.5-31.1.9-.8,3.2-2.9,4.2-3.4.2-.1.4-.1.6-.1v.1Z"/>
            <path :fill="color" d="M190.4,127.5c8.6,3.2,18.8,10.1,21.4,19.4,1.9,6.7.9,14.6-2.8,20.5-4.7-5.4-9.7-11.1-13.4-17.2-4.2-7-6.4-14.5-5.3-22.7h.1Z"/>
          </g>
          <g>
            <path :fill="color" d="M236.9,178.3c-3.3,12.6-12.9,24.7-25.8,28.2-7.4,2-14.9,1.3-22-1.3-.2-.2.8-1.3,1-1.5,2.4-2.8,6.1-6.2,9-8.6,10.8-9,24.1-14,37.8-16.8h0Z"/>
            <path :fill="color" d="M177,207.6c-1.7-14.3-3.8-30.5,6.2-42.3,5.8,6,9.8,14.8,8.6,23.2-1.1,8.1-7.4,15.8-14.8,19.1Z"/>
          </g>
        </g>
      </g>
      <g>
        <g>
          <g>
            <path :fill="color" d="M22.5,60.9c3.2,6.5,6,13.7,7.9,20.7,2.6,9.5,3.2,18.6,1.6,28.3,0,.6-.9,5.1-1.2,5.1-8.3-5.5-15-14.1-16.5-24.2-1.6-10.4,1.9-21.6,8.2-29.9Z"/>
            <path :fill="color" d="M72.8,83.1c.3.3-1.7,3.2-2,3.6-5.8,7.7-15.5,12.4-24.4,15.7-1,.4-8.4,3-8.7,2.6.7-11.1,7.1-22.1,18.7-24.2,5.8-1.1,11.1.1,16.4,2.3Z"/>
          </g>
          <g>
            <path :fill="color" d="M36.3,169.2c-13.5-1.1-24.9-9.9-29.1-22.8-2.2-6.8-2.7-14.5-1.5-21.5.3-.2,3.9,2.4,4.4,2.8,9.4,6.9,16.6,16,21.3,26.7,2.1,4.7,3.9,9.7,4.9,14.8Z"/>
            <path :fill="color" d="M60.6,118.4c.1,4.5-.4,9.1-1.7,13.4-1.1,3.7-2.8,6.5-5.1,9.5-4.4,5.9-10.2,11.1-15.8,15.8-.4,0-1.5-4-1.6-4.7-2.9-14.1,4.6-25.5,17-31.5.9-.4,6.8-3,7.2-2.5Z"/>
          </g>
        </g>
        <g>
          <path :fill="color" d="M19.5,191c7.5.6,15.1,1.9,22.3,4.1,8.7,2.7,16.6,7.1,23.7,12.6.7.5,4.7,3.6,4.6,4.1-9,5-20.4,5.8-29.8,1.6-7-3.1-13.7-9.6-17.7-16.1-.4-.7-3.4-6-3.1-6.3h0Z"/>
          <path :fill="color" d="M64.1,158.4c8.3,13,4.3,28.5,1,42.5-.5.5-5-3.9-5.5-4.4-9.4-10.3-8.8-24,.3-34.3.7-.7,2.9-3.1,3.5-3.6.2-.1.4-.3.7-.2Z"/>
        </g>
      </g>
    </g>
    <g>
      <path id="check" :fill="color" d="M146.5,171.5c2.2,1.9,4.2,4.8,6.4,6.8.6,1,.6,2.7,0,3.7-9.7,10.5-19.5,20.8-29.5,31-1.2,1.2-4.5,5.3-5.7,5.8-.9.4-1.6.4-2.5.1-7.3-6.9-13.9-14.7-21-21.8-1.2-1.3-1.6-2.9-.5-4.5.8-1.2,5.3-6,6.4-6.8,1.3-1,2.5-1.1,3.9-.1l12.5,13.1,26.3-27.7c1.2-.9,2.7-.7,3.8.3l-.1.1Z"/>
      <path id="box" :fill="color" d="M91.8,146.4h62.4c7.3.4,13.3,5.7,14.5,12.9.4,21.8.4,43.7,0,65.5-1.1,6.3-6.1,11.3-12.4,12.5-22.1.4-44.4.5-66.5,0-6.8-1.4-11.9-7.1-12.4-14v-62.9c.8-7.7,6.7-13.4,14.4-14.1v.1ZM85.5,154.7c-1.5,1.5-2.7,4-2.8,6.1.2,21.3-.3,42.6.3,63.8,1.1,4.2,4.6,7.2,8.9,7.7h62.3c5.1-.5,9-4.5,9.4-9.6v-61.5c-.4-4.5-3.5-8.2-7.9-9.3-21.7-.4-43.6-.5-65.3,0-1.7.4-3.7,1.5-4.9,2.7v.1Z"/>
    </g>
    <path id="statuette" :fill="color" d="M103.7,139.6c-.3-9.4.5-18.7,3-27.7,0-.2-2.5-.8-3.1-1.1-6.6-2.8-7.1-9.5-8.7-15.8-2-8.5-6.1-26-2.4-33.9,4.5-9.5,21.5-6.5,22.3-18.2.2-3.1-1.1-3.7-2-6.3-1.3-3.8-2.1-12.8-.8-16.5,3.5-10.8,20.2-10.2,22.7,1,.9,4,.1,12-1.3,15.9-.4,1-1.7,3.1-1.8,3.7-1.8,12.9,15.9,11,21.3,18.8,5.2,7.5.6,26.4-1.4,35-.8,3.2-2.4,11.1-4.5,13.3-1.8,2-4.9,3.3-7.5,3.9,2.5,9,3.6,18.6,3,27.9h-38.8Z"/>
  </svg>
</template>


<script>
export default {
  name: "LogoSvg",
  props: ['color']
}
</script>


<style scoped>

</style>