<template>
  <div class="container">
    <LoadingSpinner v-if="waitingForResponse"></LoadingSpinner>
    <div v-else-if="!rawUserObject">
      no user found with id {{user_id}}
    </div>

    <div v-else class="card">
      <router-link class="card-header" :to="`../../profile/${user_id}`">
        {{rawUserObject.display_name}}<br>{{rawUserObject.auth?.email}}<br>{{rawUserObject._id}}
      </router-link>

      <div class="card-body">
<!--        <pre>{{user}}</pre>-->
        <json-viewer :value="rawUserObject" copyable boxed sort></json-viewer>
      </div>
    </div>

    <ProfilePage :user_id="user_id"></ProfilePage>

  </div>
</template>


<script>
import axios from "axios";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import JsonViewer from 'vue-json-viewer'
import ProfilePage from "@/pages/ProfilePage.vue";


export default {
  name: "AdminUserPage",
  components: {ProfilePage, LoadingSpinner, JsonViewer},
  props: ['user_id'],
  data() {
    return {
      waitingForResponse: false,
      rawUserObject: null,
      logoPath: `${process.env.BASE_URL}logos/`
    }
  },
  created() {
    this.waitingForResponse = true;

    axios.get(`/api/admin/user/${this.user_id}`)
        .then((response) => {
          this.rawUserObject = response.data;
        })
        .catch((err) => {
          console.error(`error calling /api/admin/user/${this.user_id} : ${err}`)
        })
        .finally(() => {
          this.waitingForResponse = false;
        });
  },
  methods: {
    dateDisplayString(_date) {
      const dateObj = ( _date instanceof Date )? _date : new Date(_date);
      return dateObj.toDateString();
    }
  }
}
</script>


<style scoped>


.card {
  margin-top: 50px;
  margin-bottom: 50px;

  .card-header {
    font-family: monospace;
    text-decoration: inherit;
    padding: 1em;

  }

}



</style>