<template>
  <div class="category-row">
    <CategoryAchievementRing
        v-for="(cat) in categories"
        :key="cat.category.name"
        :film_ids="cat.films.map(film_obj => film_obj.film_id)"
        :category="cat.category"
        :ceremony="ceremony"
        :size="cat.category.normalized_name.toUpperCase() === 'BEST PICTURE' ? '20%' : '13%'"
        :disabled="disabled"
    >
    </CategoryAchievementRing>
  </div>
</template>


<script>
import CategoryAchievementRing from "@/components/achievements/CategoryAchievementRing.vue";

export default {
  name: "CeremonyCardRow",
  components: {CategoryAchievementRing},
  props: ['ceremony', 'categories', 'disabled']
}
</script>


<style scoped>
.category-row {
  /*
  border: solid;
  border-color: lightgreen;
  border-width: 1px;
   */

  position: relative;
  text-align: center;
}
</style>