<template>

  <section class="film-card">

    <span class="language-watermark">
      <LanguageWatermark v-if="getShouldShowFlag" :language_code="film.original_language" :diameter="50" ></LanguageWatermark>
    </span>

    <div class="checkbox-and-title">
      <span class="checkbox">
        <FilmCheckbox :film_id="film._id"></FilmCheckbox>
      </span>
      <span class="film-link-container">
        <FilmLink :film_name="film.name" :film_id="film._id" :single_line="true"></FilmLink>
      </span>
      <span class="bookmark">
        <FilmBookmark :film_id="film._id"></FilmBookmark>
      </span>
    </div>

    <div v-if="show_nominees_for_category">
      <ul class="nominees-for-category">
        <li v-for="nomination in film.nominationsForCategory(show_nominees_for_category)" :key="nomination._id" :class="nomination.won? 'li_win' : 'li_nom'">
          <small><i>{{nomination.nominee}}</i></small>
        </li>
      </ul>
    </div>

    <div v-else class="winAndNomIcons">
      <img v-for="n in film.numNominations()" :key="n" :src="n <= film.numWins()? `${publicPath}win_icon.svg` : `${publicPath}nom_icon.svg`" alt="nom" width="12" height="12">
    </div>

    <div class="friends">
      <FriendIconGroup :film_id="film._id"></FriendIconGroup>
    </div>

  </section>

</template>

<script>

import FilmLink from "@/components/FilmLink.vue";
import FilmCheckbox from "@/components/FilmCheckbox.vue";
import { userStore } from "@/userStore";
import {EventBus} from "@/eventBus";
import { languageData } from "@/language-data";
import FriendIconGroup from "@/components/friends/icons/FriendIconGroup.vue";
import FilmBookmark from "@/components/FilmBookmark.vue";
import LanguageWatermark from "@/components/LanguageWatermark.vue";


export default {
  name: "FilmCard",
  components: {
    /* eslint-disable vue/no-unused-components */
    FilmLink, FilmCheckbox, LanguageWatermark, FriendIconGroup, FilmBookmark
  },
  props: ['film', 'show_nominees_for_category'],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      filmViewed: false,
      languageData: languageData,
      viewed_by_friends: []
    }
  },
  created() {
    this.updateFilmViewed();
    EventBus.on('viewed-state-updated', (_film_id) => {
      if (this.film_id === _film_id) {
        this.updateFilmViewed();
      }
    });

    this.viewed_by_friends = userStore.user.friends.filter((f) => {
      return f.viewed.includes(this.film_id)
    });
  },
  computed: {
    getShouldShowFlag() {
      return (this.film.original_language !== 'en');
    },
    flagPath() {
      return `${this.publicPath}flags/${this.languageData.getFlagCode(this.original_language)}.svg`;
    },
    languageName() {
      return this.languageData.getLanguageName(this.original_language);
    }
  },

  methods: {
    updateFilmViewed() {
      this.filmViewed = userStore.isFilmMarkedViewed(this.film_id);
    },

    onViewedStateUpdated() {
      this.updateFilmViewed();
    },
  }
}
</script>

<style scoped>
.film-card {
  border-width: thin;
  padding: 10px 0 5px 0;
  font-size: smaller;
}

.checkbox-and-title {
  display: block;
  margin: 0px;
  width: 100%;

  & .checkbox {
    margin-top: -1px;
    margin-right: 10px;
  }
  & .film-link-container {
    /*background-color: lightpink;*/
    display: inline-block;
    width: calc(100% - 60px);
  }
  & .bookmark {
    margin-left: 10px;
    float: right;
  }
}

.poster-image {
  float: right;
  width:10%!important;


}

.winAndNomIcons {
  margin-left: 26px;
  font-size: 8px;

  & img {
    margin-right: 1px;
    margin-left: 1px;
  }
}

.friends {
  opacity: 75%;
  margin-left: 21px;
}

.nominations-and-friends {
  display: flex;
  margin: 0px;

  & .winAndNomIcons {
    display: inline-block;
    width: 100%;

    margin-left: 26px;
    font-size: 8px;

    & img {
      margin-right: 1px;
      margin-left: 1px;
    }
  }

  & .friends {
    opacity: 60%;
    margin-right: 10px;
  }
}

.language-watermark {
  float: right;
  margin-right: 80px;
}

.nominees-for-category {
  margin-bottom: 0;
}

li {
  text-align: left;
  list-style: none;
  margin: 0;
  padding: 0px 0 0px 20px;
  list-style: none;
  background-repeat: no-repeat;
  background-position-x: 0px;
  background-position-y: 4px;
  background-size: 14px;
}

.li_win {
  background-image: url('@/../public/win_icon.svg');
}

.li_nom {
  background-image: url('@/../public/nom_icon.svg');
}


</style>