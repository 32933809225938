<template>
  <div class="modal fade" id="generic-modal" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">

        <div v-if="this.$slots.header" class="modal-header">
          <slot name="header"></slot>
          <!-- X button for closing dialog -->
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <slot></slot>
        </div>

        <div v-if="this.$slots.footer" class="modal-footer">
          <slot name="footer"></slot>
        </div>

      </div>

    </div>
  </div>

</template>

<script>
import {Modal} from "bootstrap";

export default {
  name: "GenericModal",
  emits: ['modal-closed'],
  mounted() {
    const theModal = new Modal('#generic-modal', {});
    theModal.show();
    document.getElementById('generic-modal')
        .addEventListener('hidden.bs.modal', this.onModalClosed);
  },

  methods: {
    onModalClosed() {
      this.$emit('modal-closed', null);
    },
  }
}
</script>

<style scoped>

  .modal-dialog {
    line-height: var(--bs-body-line-height);
    font-size: var(--bs-body-font-size);
    min-width: min(var(--bs-modal-width), calc(100vw - (var(--bs-modal-margin) * 2)));


    .modal-body {
      font-size: small;
    }
  }

</style>