<template>
    <a class="film-link" :class="displayAsViewed ? 'viewed-film-link' : ''" @click="show_modal=true">
      <span :class="film_name_classes">{{film_name}}{{film_year_text}}</span><span v-if="displayAsBookmarked" class="fa fa-bookmark"></span><LanguageIcon v-if="show_flag_for_language_code" class="language-icon" :language_code="show_flag_for_language_code" :height="5" :superscript="true"></LanguageIcon>
    </a>
    <FilmOrCategoryModal v-if="show_modal" :film_name="film_name" :film_id="film_id" @modal-closed="show_modal=false"></FilmOrCategoryModal>
</template>


<script>
import { userStore } from "@/userStore";
import { EventBus } from "@/eventBus";
import FilmOrCategoryModal from "@/components/modal/FilmOrCategoryModal.vue";
import LanguageIcon from "@/components/LanguageIcon.vue";

export default {
  name: "FilmLink",
  components: {
    LanguageIcon,
    FilmOrCategoryModal
  },
  props: ['film_name', 'film_id', 'film_year', 'no_strikethrough', 'single_line', 'show_if_bookmarked', 'show_flag_for_language_code'],
  computed: {
    film_year_text() {
      return ( this.film_year? ` (${this.film_year})` : '' );
    },
    film_name_classes() {
      let className = 'film-name';

      if ( this.single_line ) {
        className += ' single-line';
      }

      if ( this.show_if_bookmarked && this.show_flag_for_language_code ) {
        className += ' room-for-two-icons';
      } else if ( this.show_if_bookmarked || this.show_flag_for_language_code ) {
        className += ' room-for-one-icon';
      }

      return className;

    }
  },
  data() {
    return {
      show_modal: false,
      displayAsViewed: false,
      displayAsBookmarked: false,
      userStore
    }
  },
  created() {
    this.updateFilmViewed();
    this.updateFilmBookmarked();
    EventBus.on('viewed-state-updated', (_film_id) => {
      if (this.film_id === _film_id) {
        this.updateFilmViewed();
      }
    });
    EventBus.on('bookmark-state-updated', (_film_id) => {
      if (this.film_id === _film_id) {
        this.updateFilmBookmarked();
      }
    });

  },
  methods: {
    updateFilmViewed() {
      this.displayAsViewed = !this.no_strikethrough && userStore.isFilmMarkedViewed(this.film_id);
    },
    updateFilmBookmarked() {
      if ( this.show_if_bookmarked ) {
        this.displayAsBookmarked = userStore.isFilmBookmarkedAndUnviewed(this.film_id);
      }
    }
  }
}
</script>

<style scoped>

.film-link {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;

  &.viewed-film-link {
    text-decoration: line-through;
    opacity: 0.5;
    .fa {
      text-decoration: none;
    }
  }

  &:hover {
    color: var(--bs-link-hover-color);
  }
}

.film-name {

  display: inline-block;
  vertical-align: bottom;
  text-decoration: inherit;

  &.single-line {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    max-width: 100%;

    &.room-for-one-icon {
      max-width: -webkit-calc(100% - 1em);
      max-width: -moz-calc(100% - 1em);
      max-width: calc(100% - 1em);
    }
    &.room-for-two-icons {
      max-width: -webkit-calc(100% - 2em);
      max-width: -moz-calc(100% - 2em);
      max-width: calc(100% - 2em);
    }
  }
}




.language-icon {
  display: inline-block;
  padding-left: 0.2em;
  opacity: 80%;
}

.fa-bookmark {
  display: inline-block;
  color: coral;
  font-size: 50%;
  text-indent: 0px;
  padding-left: 0.3em;

  vertical-align: super;
}


</style>