<template>
  <div id="snackbar" ref="snackbar">

    <div v-if="displayToast?.type === 'GENERIC'">
      <img :src=displayToast.image class="toast-image">
      <span v-if="displayToast.message" v-html="displayToast.message" :class="{disabled: disabled}"></span>
    </div>

    <div v-else-if="displayToast?.type === 'ACHIEVEMENT'">

      <div class="achievement-header">
        <AchievementRing
            class="achievement-ring"
            :total_segments="displayToast.numRingSegments"
            :filled_segments="displayToast.numRingSegments"
            size="32px"
            :center_img="displayToast.image"
        ></AchievementRing>
        <div v-if="displayToast.headerText" v-html="displayToast.headerText" class="header-text"></div>
      </div>

      <div v-if="displayToast.message" v-html="displayToast.message" class="message-text"></div>
    </div>

  </div>
</template>


<script>
import {EventBus} from "@/eventBus";
import AchievementRing from "@/components/achievements/AchievementRing.vue";
import {Film} from "@/film";

class ToastMessage {
  constructor(_event, _eventData) {

    switch (_event) {
      case 'toast-notification':
        this.type = 'GENERIC';
        this.message = _eventData.message;
        this.image = _eventData.image || 'logo.svg';
        break;

      case 'achievement-notification':

        this.type = 'ACHIEVEMENT';
        switch (_eventData.achievementType) {
          case 'CATEGORY_COMPLETE':
            this.headerText = "category complete";
            this.message = `<b>${Film.categoryDisplayNameHtml(_eventData.category)}</b><br>${Film.getCeremonyOrdinalText(_eventData.ceremony)} ceremony`;
            this.numRingSegments = _eventData.numFilmsViewed;
            this.image = _eventData.image || Film.getCategoryAchievementImage(_eventData.normalized_category);
            break;

          default:
            this.headerText = "achievement";
            this.message = _eventData.message;
            this.numRingSegments = _eventData.numRingSegments || 1;
            this.image = _eventData.image || 'gold-check.svg';
            break;
        }
        break;

      default:
        this.type = 'UNKNOWN';
        break;
    }
  }
}

export default {
  name: "ToastNotification",
  components: {AchievementRing},

  data() {
    return {
      toastQueue: [] // array of ToastMessage objects
    }
  },

  created() {
    EventBus.on('toast-notification', (_data) => {
      this.toastQueue.push(new ToastMessage('toast-notification', _data));
      if ( this.toastQueue.length === 1 ) {
        this.showToast();
      }
    });

    EventBus.on('achievement-notification', (_data) => {
      this.toastQueue.push(new ToastMessage('achievement-notification', _data));
      if ( this.toastQueue.length === 1 ) {
        this.showToast();
      }
    });

  },
  computed: {
    displayToast() {
      return this.toastQueue.length? this.toastQueue[0] : null;
    },
  },
  methods: {
    showToast() {
      this.$refs.snackbar.classList.add('show');
      setTimeout(this.hideToast, 5900);
    },
    hideToast() {
      this.$refs.snackbar.classList.remove('show');
      this.toastQueue.shift();
      if ( this.toastQueue.length ) {
        setTimeout(this.showToast, 100);
      }
    }
  }
}
</script>

<style scoped>
  #snackbar {
    visibility: hidden;
    /*min-width: 250px;*/
    width: 300px;
    max-width: 80%;
    overflow: hidden;

    /*left: 50%;
    margin-left: -125px;*/
    right: 20px;

    background-color: FloralWhite;
    color: var(--bs-body-color);
    text-align: center;
    border-radius: 2px;
    padding: 16px 16px 25px 16px;
    position: fixed;
    z-index: 1;

    bottom: 30px;
    font-size: 17px;

    border-style: solid;
    border-radius: 0.3em;
    border-color: var(--mol-color-gold);

  }

  #snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 5.5s;
    animation: fadein 0.5s, fadeout 0.5s 5.5s;
  }

  @-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }

  @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }

  @-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }

  @keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }

  .toast-image {
    width: 32px;
    float: left;
  }


  .achievement-header {

    text-align: center;
    margin-bottom: 0.5em;

    .achievement-ring {
      display: inline-block;
      margin-right: 0.5em;
      margin-bottom: -1cap;
    }

    .header-text {
      display: inline;
      color: var(--mol-color-gold);
      font-size: 20px;
      font-weight: bold;
    }
  }

  .message-text {
    color: var(--bs-secondary);
  }



</style>