<template>
  <router-link v-if="link_to_friends_profile" class="card friend-card link-to-profile" :to="`profile/${friend._id}`">
    <div class="card-body">
      <div class="d-flex">
        <div class="p-2 w-100">
          <ProfileFilmsViewedGraphic class="profile-films-viewed-graphic" :user=friend width="100%" height="100%" color="LightBlue"></ProfileFilmsViewedGraphic>
          <h4 class="friend-name">
            <ProfilePicture :user="friend" size="50"></ProfilePicture> <ProfileName :user="friend"></ProfileName>
          </h4>
        </div>
        <div class="p-2 flex-shrink-1">
          <a v-if="cancel_button_text" class="remove-friend-button" href="#" :title="cancel_button_text" @click.prevent="$emit('cancel-button-clicked');">
            <i class="fa fa-user-times"></i>
          </a>
        </div>
      </div>
    </div>
    <slot></slot>
  </router-link>

  <div v-else class="card friend-card">
    <div class="card-body">
      <div class="d-flex">
        <div class="p-2 w-100">
          <ProfileFilmsViewedGraphic v-if="friend.viewed" class="profile-films-viewed-graphic" :user=friend width="100%" height="100%" color="LightBlue"></ProfileFilmsViewedGraphic>
          <h4 class="friend-name">
            <ProfilePicture :user="friend" size="50"></ProfilePicture> <ProfileName :user="friend"></ProfileName>
          </h4>
        </div>
        <div class="p-2 flex-shrink-1">
          <a v-if="cancel_button_text" class="remove-friend-button" href="#" :title="cancel_button_text" @click.prevent="$emit('cancel-button-clicked');">
            <i class="fa fa-user-times"></i>
          </a>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>

</template>


<script>

import ProfilePicture from "@/components/profile/ProfilePicture.vue";
import ProfileFilmsViewedGraphic from "@/components/profile/ProfileFilmsViewedGraphic.vue";
import ProfileName from "@/components/profile/ProfileName.vue";

export default {
  name: 'FriendCard',
  components: {ProfileName, ProfileFilmsViewedGraphic, ProfilePicture},
  props: ['friend', 'cancel_button_text', 'link_to_friends_profile'],
  emits: ['cancel-button-clicked'],
  computed: {
  },
  methods: {
  }
}
</script>


<style scoped>

.profile-films-viewed-graphic {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.friend-card {
  isolation: isolate;
  overflow: hidden;

  text-decoration: inherit;

  background-color: aliceblue;
  border-color: cornflowerblue;
  color: cornflowerblue;
  margin: 20px;

  &.link-to-profile {
    &:hover {
      box-shadow: 0 0 0 1px cornflowerblue;
    }
  }

}

.remove-friend-button {
  float: right;
  width: fit-content;

  .fa {
    color: var(--bs-card-border-color);
    font-size: 20px;
  //opacity: 40%;
  }
}
.alert {
  .invite-text {
    margin-bottom: 20px;
  }
  .friend-name {
    font-weight: 500;
    margin-bottom: 0px;
  }

  button {
    margin-top: 5px;
    margin-right: 5px;
    margin-left: 5px;
  }
}
.profile-pic {
  border-radius: 50%;
  margin-top: -4px;
}
</style>