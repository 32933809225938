<template>
  <div class="container">
    <div class="icon-row">
      <img :src="`${publicPath}win_icon.svg`" alt="Logo" class="logo-image">
      <img :src="`${publicPath}win_icon.svg`" alt="Logo" class="logo-image">
      <img :src="`${publicPath}win_icon.svg`" alt="Logo" class="logo-image">
      <img :src="`${publicPath}nom_icon.svg`" alt="Logo" class="logo-image">
      <img :src="`${publicPath}win_icon.svg`" alt="Logo" class="logo-image">
    </div>
    <div class="page-not-found-text text-my-oscar-list">PAGE<span class="text-oscar">NOT</span>FOUND</div>

    <router-link class="my-oscar-list-link" to="/welcome">
      <span class="text-return-to">RETURN TO</span><br>

      <span class="text-my-oscar-list">
        <img :src="`${publicPath}logo.svg`" alt="Logo" class="logo-image">MY<span class="text-oscar">OSCAR</span>LIST
      </span>
    </router-link>
  </div>

</template>


<script>

export default {
  name: "NotFoundPage",
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  },

}
</script>


<style scoped>

.container {
  text-align: center;
}

.icon-row {
  margin: 50px auto 0 auto;
  max-width: 400px;
  width: 75%;
  img {
    margin-bottom: 1em;
    width: 20%;
  }

}

.text-my-oscar-list {

  font-weight: 300;

  .text-oscar {
    font-weight: 600;
    padding-left: 0.05em;
    padding-right: 0.05em;
  }
}
.page-not-found-text {
  font-size: xx-large;
  color: #b49823;
  text-align: center;
}

.my-oscar-list-link {

  background-color: cornsilk;
  border-style: solid;
  border-width: 1px;
  padding: 10px;

  &:hover {
    border-width: 2px;
    border-color: #b49823;
    padding: 9px;
  }


  width: fit-content;

  margin-top: 100px;

  margin-left: auto;
  margin-right: auto;

  display: block;
  color: inherit;
  text-decoration: inherit;


  .text-return-to {
    font-size: medium;
  }

  .text-my-oscar-list {
    font-size: x-large;

    img {
      margin-top: -0.2cap;
      margin-right: 0.1em;
      width: 1.3em;
    }
  }
}

</style>