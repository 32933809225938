import {ceremonies} from "@/ceremonies";

class StreamingProvider {

    constructor(apiResponseObj) {
        this.id = apiResponseObj.id;
        this.name = apiResponseObj.name;
        this.logo_path = apiResponseObj.logo_path;
    }
}

class Nomination {

    constructor(apiResponseObj) {
        // take all the apiResponse properties
        Object.assign(this, apiResponseObj);
    }

    static categoryDisplayNameHtml(_categoryName) {
        return _categoryName

            // display in all-caps
            .toUpperCase()

            // add word-splitting suggestions
            .replace('CINEMATOGRAPHY', 'CINEMA&shy;TOGRAPHY')
            .replace('INTERNATIONAL', 'INTER&shy;NATIONAL')
            .replace('DOCUMENTARY', 'DOCU&shy;MENTARY')
            .replace('PRODUCTION', 'PRO&shy;DUCTION')
            .replace('HAIRSTYLING', 'HAIR&shy;STYLING')
            .replace('ANIMATED', 'ANI&shy;MATED')

            // shorten some of these older, long-winded category names
            .replace('(SCREENPLAY WRITTEN DIRECTLY FOR THE SCREEN)', '(ORIGINAL SCREENPLAY)')
            .replace('(STORY AND SCREENPLAY--WRITTEN DIRECTLY FOR THE SCREEN)', '(ORIGINAL SCREENPLAY)')
            .replace('(SCREENPLAY WRITTEN DIRECTLY FOR THE SCREEN--BASED ON FACTUAL MATERIAL OR ON STORY MATERIAL NOT PREVIOUSLY PUBLISHED OR PRODUCED)', '(ORIGINAL SCREENPLAY)')
            .replace('(STORY AND SCREENPLAY--BASED ON FACTUAL MATERIAL OR MATERIAL NOT PREVIOUSLY PUBLISHED OR PRODUCED)', '(ORIGINAL SCREENPLAY)')
            .replace('(STORY AND SCREENPLAY--BASED ON MATERIAL NOT PREVIOUSLY PUBLISHED OR PRODUCED)', '(ORIGINAL SCREENPLAY)')


            .replace('(SCREENPLAY BASED ON MATERIAL PREVIOUSLY PRODUCED OR PUBLISHED)', '(ADAPTED SCREENPLAY)')
            .replace('(SCREENPLAY--BASED ON MATERIAL FROM ANOTHER MEDIUM)', '(ADAPTED SCREENPLAY)')
            .replace('(SCREENPLAY BASED ON MATERIAL FROM ANOTHER MEDIUM)', '(ADAPTED SCREENPLAY)')
            .replace('(SCREENPLAY ADAPTED FROM OTHER MATERIAL)', '(ADAPTED SCREENPLAY)')

            .replace('(MUSIC SCORE OF A DRAMATIC OR COMEDY PICTURE)', '(SCORE)')
            .replace('(MUSIC SCORE OF A DRAMATIC PICTURE)', '(SCORE)')
            .replace('(SCORING OF A MUSICAL PICTURE)', '(MUSICAL SCORE)')

            .replace('(ORIGINAL SONG SCORE AND ITS ADAPTATION -OR- ADAPTATION SCORE)', '(ADAPTATION OR ORIGINAL SONG SCORE)')
            .replace('(ORIGINAL SONG SCORE AND ITS ADAPTATION OR ADAPTATION SCORE)', '(ADAPTATION OR ORIGINAL SONG SCORE)')
            .replace('(SCORING: ORIGINAL SONG SCORE AND ADAPTATION -OR- SCORING: ADAPTATION)', '(ADAPTATION OR ORIGINAL SONG SCORE)')
            .replace('(SCORING: ADAPTATION AND ORIGINAL SONG SCORE)', '(ADAPTATION OR ORIGINAL SONG SCORE)')
            .replace('(SCORE OF A MUSICAL PICTURE--ORIGINAL OR ADAPTATION)', '(ADAPTATION OR ORIGINAL SONG SCORE)')

            .replace('(SCORING OF MUSIC--ADAPTATION OR TREATMENT)', '(ADAPTED MUSIC SCORE)')

            .replace('(ORIGINAL SCORE--FOR A MOTION PICTURE [NOT A MUSICAL])', '(ORIGINAL MUSIC SCORE)')
            .replace('(MUSIC SCORE--SUBSTANTIALLY ORIGINAL)', '(ORIGINAL MUSIC SCORE)')

            .replace('(BLACK-AND-WHITE)', '(B&W)')
            .replace('SPECIAL ACHIEVEMENT AWARD', 'SPECIAL ACHIEVEMENT')

            //.replace('', '')
            ;
    }
}

export class FilmTitleInfo {
    constructor(apiResponseObj) {
        this._id = apiResponseObj._id;
        this.name = apiResponseObj.name;
        this.year = apiResponseObj.year;
        this.display_priority = apiResponseObj.display_priority;

        if ( apiResponseObj.nomCount ) {
            this.nomCount = apiResponseObj.nomCount;
            this.winCount = apiResponseObj.winCount;
        } else if ( apiResponseObj.nominations ) {
            this.nomCount = apiResponseObj.nominations.length;
            this.winCount = apiResponseObj.nominations.filter(n => n.won).length;
        }
    }
    toString() {
        return `${this.name}: ${this.nominations.length} nominations`;
    }

    numNominations() {
        return this.nomCount;
    }
    numWins() {
        return this.winCount;
    }
}

export class Film {
    constructor(apiResponseObj) {

        // for the most part, take all the apiResponse properties
        Object.assign(this, apiResponseObj);

        // some should be converted to proper types
        this.release_date = new Date(this.release_date);
        this.runtime = Number(this.runtime);
        this.nominations = this.nominations.map(n => new Nomination(n));

        this.streamingProviders = [];
        if ( apiResponseObj.streamingProviders?.length > 0 ) {
            this.streamingProviders = apiResponseObj.streamingProviders?.map(p => new StreamingProvider(p));
        }
    }
    toString() {
        return `${this.name} (${this.year}): ${this.nominations.length} nominations`;
    }

    genreText(_seperatingCharacter) {

        if ( !this.genres?.length ) {
            return "";
        }

        let text = '';
        if ( !_seperatingCharacter ) {
            _seperatingCharacter = ' ';
        }

        this.genres.forEach((genre, index, array) => {
            text += genre;
            if ( index !== (array.length - 1) ) {
                text += _seperatingCharacter;
            }
        });
        return text;
    }

    runtimeText() {
        const hours = Math.floor(this.runtime / 60);
        const minutes = this.runtime % 60;
        return ( (hours > 0)? `${hours}h ${minutes}m` : `${minutes}m` );
    }

    numNominations() {
        return this.nominations.length;
    }
    numWins() {
        return this.wins().length;
    }

    wins() {
        return this.nominations.filter(n => n.won);
    }

    imdbLink() {
        if (!this.imdb?.id) {
            const urlSafeMovieName = encodeURI(`"${this.name.replace(' ', '+')}"`);
            let url = `https://www.imdb.com/search/title/?title=${urlSafeMovieName}`;

            if ( this.year ) {
                url += `&release_date=${this.year},${this.year + 1}`;
            }
            return url;
        }
        return `https://www.imdb.com/title/${this.imdb.id}`;
    }

    nominationsForCategory(_category) {
        let noms = this.nominations.filter(nom => nom.category.name == _category);

        return noms.sort((nom1, nom2) => {
            if (nom1.won && !nom2.won) {
                return -1;
            }
            if (nom2.won && !nom1.won) {
                return 1;
            }
            return 0;
        });
    }

    static sortFilmsAlphabetically(filmlist) {
        filmlist.sort((film1, film2) => {

            let name1 = film1.name.toLowerCase();
            if (name1.startsWith('a ')) {
                name1 = name1.slice(2);
            }
            if (name1.startsWith('the ')) {
                name1 = name1.slice(4);
            }

            let name2 = film2.name.toLowerCase();
            if (name2.startsWith('a ')) {
                name2 = name2.slice(2);
            }
            if (name2.startsWith('the ')) {
                name2 = name2.slice(4);
            }

            if (name1 < name2) {
                return -1;
            }
            if (name2 < name1) {
                return 1;
            }
            return 0;
        });
    }

    static sortFilmsByPrioirity(filmlist) {

        filmlist.sort((film1, film2) => {

            // Instead of simply comparing two films' display_priorities, this looks through the nominations lists of
            //  each film, to more reasonably sort ties (like, two non-winning best picture nominees have the same
            //  display_priority; but the one that won best actor should definitely be treated as higher priority).
            for ( let i = 0; i < Math.min(film1.nominations.length, film2.nominations.length); i++ ) {
                if ( film1.nominations[i].display_priority < film2.nominations[i].display_priority ) {
                    return 1;
                } else if ( film1.nominations[i].display_priority > film2.nominations[i].display_priority ) {
                    return -1;
                }
            }
            return (film2.nominations.length - film1.nominations.length)

            //return (film2.display_priority - film1.display_priority);
        });

    }

    static sortFilmsChronologically(filmlist) {
        filmlist.sort((film1, film2) => {
            return (film1.year - film2.year);
        });
    }

    static getNominatedCategoriesForFilms(_filmlist) {

        let categoriesWithPriorities = []; // array of {name, priority} objects
        _filmlist.forEach((film) => {
            film.nominations.forEach((nom) => {
                const foundCategory = categoriesWithPriorities.find(cat => {
                    return (nom.category.name === cat.name);
                });
                if ( !foundCategory ) {
                    categoriesWithPriorities.push({
                        name: nom.category.name,
                        priority: nom.category.display_priority
                    });
                }
            })
        });

        // sort by priority
        categoriesWithPriorities.sort((cat1, cat2) => {
           return cat2.priority - cat1.priority;
        });

        // just return the list of names
        return categoriesWithPriorities.map(cat => cat.name);
    }

    static filterFilmsNominatedForCategory(_filmlist, _category) {

        return _filmlist.filter((film) => {
            return (film.nominations.filter(nom => nom.category.name == _category).length > 0);
        });
    }

    static getCeremonyHeaderText(_ceremony) {
        const ceremony_num = Number(_ceremony);
        if ( ceremony_num <= 0 ) {
            return '';
        }
        return `${Film.getCeremonyOrdinalText(ceremony_num)} (${Film.getCeremonyYearText(ceremony_num)})`
    }

    static getCeremonyOrdinalText(_ceremony) {
        const ceremony_num = Number(_ceremony);
        if ( ceremony_num <= 0 ) {
            return '';
        }
        return `${ceremony_num}${
            (ceremony_num % 10 == 1 && ceremony_num % 100 != 11) ? 'st' :
                (ceremony_num % 10 == 2 && ceremony_num % 100 != 12) ? 'nd' :
                    (ceremony_num % 10 == 3 && ceremony_num % 100 != 13) ? 'rd' :
                        'th'}`;
    }
    static getCeremonyYearText(_ceremony) {
        const ceremony_num = Number(_ceremony);
        return ( ceremony_num <= 0 )? '' : ( ceremony_num <= 6 )? `${1926 + ceremony_num}/${27 + ceremony_num}` : `${1927 + ceremony_num}`;
    }

    static getDateOfCeremony(_ceremony) {

        const dateOfGivenCeremony = ceremonies.find((obj) => {
            return ( obj.ceremony === Number(_ceremony) );
        });

        return new Date(dateOfGivenCeremony?.date);
    }

    static categoryDisplayNameHtml(_categoryName) {
        return Nomination.categoryDisplayNameHtml(_categoryName);
    }

    static getCategoryAchievementImage(_normalizedCategoryName) {

        switch ( _normalizedCategoryName?.toUpperCase() ) {
            case "BEST PICTURE":
                return 'achievements/film-reel.svg';

            case "ACTOR IN A LEADING ROLE":
            case "ACTRESS IN A LEADING ROLE":
            case "ACTOR IN A SUPPORTING ROLE":
            case "ACTRESS IN A SUPPORTING ROLE":
                return 'achievements/comedy-tragedy.svg';

            case "DIRECTING":
                return 'achievements/clapper-board.svg';

            case "WRITING (ORIGINAL SCREENPLAY)":
            case "WRITING (ADAPTED SCREENPLAY)":
                return 'achievements/typewriter.svg';

            case "CINEMATOGRAPHY":
                return 'achievements/film-camera.svg';

            case "VISUAL EFFECTS":
                return 'achievements/explosion.svg';

            case "PRODUCTION DESIGN":
                return 'achievements/paintbrush-and-drafting-triangle.svg';

            case "FILM EDITING":
                return 'achievements/film-and-scissors.svg';

            case "SOUND":
                return 'achievements/boom-mic.svg';

            case "MUSIC (ORIGINAL SCORE)":
                return 'achievements/sheet-music.svg';

            case "MUSIC (ORIGINAL SONG)":
                return 'achievements/headphones.svg';

            case "INTERNATIONAL FEATURE FILM":
                return 'achievements/globe.svg';

            case "MAKEUP AND HAIRSTYLING":
                return 'achievements/dressing-room-vanity.svg';

            case "COSTUME DESIGN":
                return 'achievements/dress.svg';

            case "ANIMATED FEATURE FILM":
                return 'achievements/animation-desk.svg';

            case "ANIMATED SHORT FILM":
                return 'achievements/animation-desk-with-timer.svg';

            case "DOCUMENTARY FEATURE FILM":
                return 'achievements/handheld-camera.svg';

            case "DOCUMENTARY SHORT FILM":
                return 'achievements/handheld-camera-with-timer.svg';

            case "LIVE ACTION SHORT FILM":
                return 'achievements/film-reel-with-timer.svg';

            case "HONORARY AWARD":
                return 'achievements/ribbon.svg';
        }
        return 'achievements/checkmark.svg';
    }

}