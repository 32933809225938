<template>
  <CategoryLink :category="category.name" :ceremony="ceremony" :disabled="disabled">
    <AchievementRing
        :total_segments="numFilms"
        :filled_segments="numViewed"
        :size="size"
        :center_img="centerImg"
        :display_text="categoryDisplayNameHtml"
        :disabled="disabled"
    ></AchievementRing>
  </CategoryLink>
</template>


<script>
import { userStore } from "@/userStore";
import CategoryLink from "@/components/CategoryLink.vue";
import AchievementRing from "@/components/achievements/AchievementRing.vue";
import {Film} from "@/film";

export default {
  name: "CategoryAchievementRing",
  components: {AchievementRing, CategoryLink},
  props: ['category', 'ceremony', 'film_ids', 'size', 'disabled'],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      show_modal: false,
      winner_on_top: false,
      unique_film_ids: this.film_ids.filter((film_id, idx, array) => array.indexOf(film_id) === idx)
    }
  },
  computed: {
    numFilms() {
      return this.unique_film_ids.length;
    },
    numViewed() {
      return this.unique_film_ids.filter((film_id => userStore.isFilmMarkedViewed(film_id))).length;
    },
    categoryDisplayNameHtml() {
      return `<div style="margin-top: 0.5em;">${Film.categoryDisplayNameHtml(this.category.name)}</div>`;
    },
    centerImg() {
      // we only provide a center image if all the films have been viewed
      let image = Film.getCategoryAchievementImage(this.category.normalized_name);

      if (this.numViewed < this.numFilms) {
        image = image.replace('achievements/', 'achievements/disabled/');
      }

      return image;


    }
  }
}
</script>


<style scoped>
</style>