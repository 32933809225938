<template>
  <LoadingSpinner v-if="!user"></LoadingSpinner>

  <div v-else class="container-fluid text-center">


    <!-- profile card -->
    <div class="card mol-center-card-width profile-card" :class="isFriend ? 'friend-colors' : isMe ? '' : 'stranger-colors'">
      <div class="card-body">
        <div class="user-id">{{user._id}}</div>
        <ProfileFilmsViewedGraphic class="profile-films-viewed-graphic" :user=user width="100%" height="100%" :color="profileFilmsViewedGraphicColor"></ProfileFilmsViewedGraphic>
        <div class="profile-info-layered-over-graphic">
          <ProfilePicture :user=user :size="100" :editable="true"></ProfilePicture>
          <ProfileName class="name-text" :user="user"></ProfileName>
        </div>
      </div>
    </div>

    <!-- achievements card -->
    <ExpandableCard class="expandable-card mol-center-card-width achievements-card" :class="isFriend ? 'friend-colors' : isMe ? '' : 'stranger-colors'">

      <template #header>
        <section class="expandable-card-header">
        {{achievementHeaderText}}
        </section>
      </template>

      <template #body>
        <span v-if="achievements">
          <AchievementRing
              v-for="(achievement, index) in achievements"
              :key="index"
              :total_segments="achievement.numFilms"
              :filled_segments="achievement.numFilms"
              :tooltip_text="achievement.tooltipHtml"
              size="32px"
              :center_img="achievement.icon"
              :display_text='`<b style="color: var(--mol-color-gold);">${achievement.displayText}</b>`'
              class="profile-achievement-ring"
          ></AchievementRing>
        </span>
      </template>
    </ExpandableCard>

    <!-- films viewed card -->
    <ExpandableCard class="expandable-card mol-center-card-width films-viewed-card" :class="isFriend ? 'friend-colors' : isMe ? '' : 'stranger-colors'">

      <template #header>
        <section class="expandable-card-header">
          {{filmHeaderText}}
        </section>
      </template>

      <template #body>
        <LoadingSpinner v-if="!filmsViewed" class="text-secondary" style="margin-top: 10px;"></LoadingSpinner>
        <div v-else v-for="decade in filmDecadeMap.keys()" :key="decade" class="decade">
          <b>{{decade}}s</b>
          <div class="film-list">
            <div v-for="film in filmDecadeMap.get(decade)" :key="film._id" >
              <FilmLink :film_name="film.name" :film_id="film._id" single_line="true" no_strikethrough="true"></FilmLink>
            </div>
          </div>
        </div>
      </template>

    </ExpandableCard>
  </div>

</template>


<script>
import {userStore} from "@/userStore";
import ProfilePicture from "@/components/profile/ProfilePicture.vue";
import ProfileFilmsViewedGraphic from "@/components/profile/ProfileFilmsViewedGraphic.vue";
import axios from "axios";
import FilmLink from "@/components/FilmLink.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ProfileName from "@/components/profile/ProfileName.vue";
import AchievementRing from "@/components/achievements/AchievementRing.vue";
import {Film} from "@/film";
import ExpandableCard from "@/components/ExpandableCard.vue";

export default {
  name: "ProfilePage",
  components: {
    ExpandableCard,
    AchievementRing,
    ProfileName, LoadingSpinner, FilmLink, ProfileFilmsViewedGraphic, ProfilePicture},
  props: ['user_id'],
  computed: {
    isMe() {
      return (this.user_id === userStore.getUserId());
    },
    isFriend() {
      return !!(
          userStore.user.friends?.find(friend => (friend._id === this.user._id)) ||
          userStore.user.friends_waiting_for_approval?.find(friend => (friend._id === this.user._id))
      );
    },
    isAdmin() {
      return this.user?.isAdmin;
    },
    isTester() {
      return this.user?.isTester;
    },
    profileFilmsViewedGraphicColor() {
      return (this.isMe ? '#decc82' : 'LightBlue');
    },
    filmHeaderText() {
      return (
          !this.filmsViewed? 'films viewed' :
              (this.filmsViewed.length === 0)? 'no films viewed yet' :
                  (this.filmsViewed.length === 1)? '1 film viewed' :
                  `${this.filmsViewed.length} films viewed`
      );
    },
    achievementHeaderText() {
      return (
          !this.achievements? 'achievements earned' :
              (this.achievements.length === 0)? 'no achievements earned yet' :
                  (this.achievements.length === 1)? '1 achievement earned' :
                      `${this.achievements.length} achievements earned`
      );
    }

  },
  data() {
    return {
      user: null,
      filmsViewed: null,
      filmDecadeMap: new Map(),
      showExpandedFilmList: false,
      showExpandedAchievementsList: false,
      achievements: null,
    }
  },
  async created() {
    return this.updateUser(this.user_id)
        .then(() => {
          return this.initProfileData();
        });
  },
  watch: {
    user_id(/*newValue, oldValue*/) {
      return this.updateUser(this.user_id)
          .then(() => {
            return this.initProfileData();
          });
    },
  },
  methods: {
    async updateUser(_userId) {

      this.user = null;

      // see if the user is someone I have quick access to: maybe me...
      if ( _userId === userStore.getUserId() ) {
        this.user = userStore.user;
        return;
      }

      // ...or a friend
      const friend = (
          userStore.user.friends?.find(friend => (friend._id === _userId)) ||
          userStore.user.friends_waiting_for_approval?.find(friend => (friend._id === _userId))
      );
      if ( friend ) {
        this.user = friend;
        return;
      }

      // this is a stranger -- we can get their info if we're an admin
      if ( userStore.isAdmin() ) {
        return axios.get(`/api/admin/profile/${_userId}`)
            .then((response) => {
              this.user = response.data;
            })
            .catch((err) => {
              console.error(`error calling /api/admin/user/${_userId} : ${err}`)
            });
      }

    },
    async initProfileData() {
      // if we can't find this user, go to the friends page
      if ( !this.user ) {
        this.$router.push({name: 'FriendsPage'});
        return;
      }

      this.filmsViewed = null;
      this.filmDecadeMap = new Map();
      this.showExpandedFilmList = false;
      this.showExpandedAchievementsList = false;
      this.achievements = null;

      axios.post(
          '/api/film/names',
          {
            film_ids: this.user.viewed
          })
          .then((response) => {
            //console.log(response);
            this.filmsViewed = response.data;
            this.filmsViewed.sort((filmA, filmB) => {
              return Number(filmB.year) - Number(filmA.year);
            });

            this.filmsViewed.forEach((film) => {
              const decade = Math.floor(Number(film.year) / 10) * 10;
              //console.log(decade);

              let films = this.filmDecadeMap.has(decade)? this.filmDecadeMap.get(decade) : [];
              films.push(film);
              this.filmDecadeMap.set(decade, films);
            });

            return axios.get(`/api/achievements/accomplished/${this.user._id}`);
          })
          .then((response) => {
            this.achievements = response.data.map(achievement => {
              achievement.icon = Film.getCategoryAchievementImage(achievement.normalizedCategory);
              achievement.displayText = Film.getCeremonyYearText(achievement.ceremony);

              if ( achievement.normalizedCategory.toUpperCase() === 'HONORARY AWARD' ) {
                achievement.tooltipHtml =
                    `Viewed each film nominated for a<br>
                    <b>Special or Honerary Award</b><br>
                    in the <b>${Film.getCeremonyOrdinalText(achievement.ceremony)} ceremony</b><br>
                    (honoring the films of ${Film.getCeremonyYearText(achievement.ceremony)})`;

              } else {
                achievement.tooltipHtml =
                    `Viewed each film nominated for<br>
                    <b>${achievement.category}</b><br>
                    in the <b>${Film.getCeremonyOrdinalText(achievement.ceremony)} ceremony</b><br>
                    (honoring the films of ${Film.getCeremonyYearText(achievement.ceremony)})`;

              }

              return achievement;
            });
          })
          .catch((err) => {
            this.filmsViewed = [];
            console.log(err);
          });
    }
  }
}
</script>


<style scoped>

.friend-colors {
  background-color: aliceblue;
  border-color: cornflowerblue;
}

.stranger-colors {
  background-color: lightgoldenrodyellow;
  border-color: yellowgreen;
}

.card {
  margin-top: 20px;
  margin-bottom: 20px;
}

.expandable-card {
  .expandable-card-header {
    font-size: x-large;
    font-weight: 500;
  }
}

.profile-card {
  isolation: isolate;
  overflow: hidden;

  .user-id {
    font-size: xx-small;
    position: absolute;
    color: lightgrey;
    top: 1em;
    left: 1em;
  }

  .profile-films-viewed-graphic {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .profile-info-layered-over-graphic {
    .name-text {
      display: block;
      margin-top: 0.5em;
      font-weight: 600;
      font-size: x-large;
    }
  }

  margin-top: 20px;
  padding: 10px;
}

.achievements-card {
  .profile-achievement-ring {
    font-weight: bold;
    margin-bottom: 3cap;
  }
}

.films-viewed-card {
  .decade {
    border: none;

    /* all but the first 'decade' will have a line on top */
    ~.decade {
      border-style: solid none none none;
      border-width: 1px;
      border-color: var(--bs-card-border-color);
      padding-top: 0.5em;
      margin-top: 0.5em;
    }
  }
  .film-list {
    margin-top: 1em;
    columns: 3 auto;
    text-align: left;
    font-size: xx-small;
  }
}




</style>