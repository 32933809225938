import { reactive } from 'vue'
import analytics from "@/analytics";
// import { set as gaSet } from "vue-gtag";

function isValidUserObject(_user) {
    return (
        _user &&
        (typeof _user._id === 'string') &&
        (typeof _user.username === 'string') &&
        Array.isArray(_user.viewed) &&
        Array.isArray(_user.watchlist) &&
        Array.isArray(_user.ceremonies_reviewed)
    );
}

export const userStore = reactive({
    user: null,

    setUser(_user) {
        // clearing the user?
        if ( !_user ) {
            if ( this.user ) {
                this.user = null;
                analytics.setUser(null);
                // gaSet({
                //     user_id: undefined,
                //     user_properties: undefined
                // });
            }
            return null;
        }

        // setting the user
        if ( !isValidUserObject(_user) ) {
            throw Error(`trying to set user that's not a valid user object: ${_user}`)
        }
        // set user in google analytics
        if ( _user._id != this.user?._id ) {
            analytics.setUser(_user);
            // gaSet({
            //     user_id: _user._id,
            //     user_properties: {
            //         mol_id: _user._id,
            //         mol_username: _user.username
            //     }
            // });
        }

        this.user = _user;

        //console.log(`set user ${this.user._id} (${this.user.username})`)
        return this.user;
    },
    getUserId() {
        return this.user?._id;
    },
    isCeremonyReviewed(_ceremony) {
        return this.user?.ceremonies_reviewed?.includes(_ceremony);
    },
    isFilmMarkedViewed(_film_id) {
        return this.user?.viewed?.includes(_film_id);
    },
    isFilmBookmarked(_film_id) {
        return this.user?.watchlist?.includes(_film_id);
    },
    isFilmBookmarkedAndUnviewed(_film_id) {
        return this.isFilmBookmarked(_film_id) && !this.isFilmMarkedViewed(_film_id);
    },
    isLoggedIn() {
        return (this.user !== null);
    },
    isAdmin() {
      return (this.isLoggedIn() && this.user.isAdmin);
    },
    isTester() {
        return (this.isLoggedIn() && this.user.isTester);
    },
    hasPermissionToSeeProfile(_userId) {

        // Admins can see anyone's profile
        if ( this.isAdmin() ) {
            return true;
        }

        // I have permission to see my own profile
        if ( _userId === this.user._id ) {
            return true;
        }

        // I have permission to see my friends' profiles
        if ( this.user.friends?.find(friend => (_userId === friend._id)) ) {
            return true;
        }

        // I have permission to see users who have invited me to be their friend
        if ( this.user.friends_waiting_for_approval?.find(friend => (_userId === friend._id)) ) {
            return true;
        }

        return false;
    },
    getFriendConnectionId() {
      return this.getUserId();
    },
    search: {
        text: '',
        type: ''
    }
});

