<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6 offset-xl-3 col-xl-6">

        <div  class="card bg-light text-center main-card">

          <form v-if="state === ESState.COLLECTING_MESSAGE" class="card-body">
            <h3 class="card-title text-dark">Contact Us</h3>
            <hr>

            <div class="form-group">
              <label for="subject">choose subject</label>
              <select class="form-control" id="subject" v-model="subject">
                <option>I'd like to report an issue</option>
                <option>I'd like to request a feature</option>
                <option>I have a question</option>
                <option>I have a comment</option>
              </select>
            </div>

            <div class="form-group" style="padding-top: 1em;">
              <label for="message">message <span :style="`color: ${textLimitColor};`">(max {{messageCharLimit}} characters)</span></label>
              <textarea class="form-control" id="message" rows="5" :maxlength="messageCharLimit" v-model="message"></textarea>
            </div>

            <button type="submit" class="btn btn-primary" :class="{disabled: (!subject || !message)}" @click.prevent="sendMessage">send message</button>
          </form>

          <div v-else-if="state === ESState.SENDING_MESSAGE" class="card-body">
            <h3 class="card-title text-dark">Contact Us</h3>
            <LoadingSpinner class="text-secondary" style="margin-top: 10px;"></LoadingSpinner>
          </div>

          <div v-else-if="state === ESState.SENT_MESSAGE" class="card-body">
            <h3 class="card-title text-dark">
              <img :src="`${this.publicPath}check-in-box.svg`" height="20">
              Message Submitted
            </h3>

            <div class="card card-body message-recap">
              <div class="subject">
                {{subject}}:
              </div>
              <div class="message">
                {{message}}
              </div>
              <div class="closing">
                <span class="user-id">user {{user._id}}</span>
                {{user.username}}<br>
                {{email}}
              </div>
            </div>

            <p>Thanks for your input!</p>
          </div>

          <div v-else-if="state === ESState.ERROR_SENDING_MESSAGE" class="card-body">
            <h3 class="card-title text-dark">Error Sending Message</h3>
            <p>There was a problem sending your message.</p>
            <router-link style="text-decoration:none;" to="/contact-us">Try again?</router-link>
          </div>

        </div>


      </div>
    </div>
  </div>
</template>






<script>
import axios from "axios";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import {userStore} from "@/userStore";

export default {
  name: "ContactUsPage",
  components: {LoadingSpinner},
  data() {
    return {
      messageCharLimit: 1000,
      publicPath: process.env.BASE_URL,
      ESState: Object.freeze({
        COLLECTING_MESSAGE: Symbol("collecting message"),
        SENDING_MESSAGE: Symbol("sending message"),
        SENT_MESSAGE: Symbol("sent message"),
        ERROR_SENDING_MESSAGE: Symbol("error sending message")
      }),
      state: null,
      subject: "",
      message: "",
      email: ""
    }
  },
  computed: {
    user() {
      return userStore.user;
    },
    textLimitColor() {
      return (
          (this.message.length >= this.messageCharLimit)? 'red' :
              (this.message.length > (this.messageCharLimit * 0.9))? 'goldenrod' :
                  'grey'
      ) ;
    }
  },
  mounted() {
    this.state = this.ESState.COLLECTING_MESSAGE;

    // // test
    // this.state = this.ESState.SENT_MESSAGE;
    // this.subject = "I'd like to request a feature";
    // this.message = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec orci justo, pulvinar ut felis eget, venenatis molestie sem. Nunc tristique condimentum felis, quis varius enim ultrices ut. Mauris elementum nisl ex, vitae suscipit tortor pretium in. Maecenas scelerisque suscipit commodo. Sed elementum mauris et metus vestibulum commodo. Aenean quis arcu lacus. Aenean in ligula sit amet nisl iaculis vestibulum eu tempor elit. Vivamus eu massa dignissim, semper lorem nec, ornare nisl. Morbi ex justo, congue eget leo id, mollis tempor tellus.";
    // this.email = 'blahblah@blah.com';
    // // test


  },
  methods: {
    async sendMessage() {
      this.state = this.ESState.SENDING_MESSAGE;
      axios
          .post("/api/user/contact-us-message", {
            subject: this.subject,
            message: this.message
          })
          .then((response) => {
            console.log(response);
            this.email = response.data.email;
            this.state = this.ESState.SENT_MESSAGE;
          })
          .catch((error) => {
            console.log(error);
            this.state = this.ESState.ERROR_SENDING_MESSAGE;
          });
    }
  }
}
</script>


<style scoped>

.main-card {
  margin-top: 20px;
  margin-bottom: 50px;
}

.form-control {
  margin-top: 10px;
  margin-bottom: 10px;
}

h3 {
  margin-top: 0.5em;
}

.message-recap {

  padding: 2em;
  margin-top: 2em;
  margin-bottom: 2em;
  text-align: left;

  .subject {
    margin-bottom: 1em;
    font-weight: 500;
  }

  .message {
  }

  .closing {
    text-align: right;
    font-weight: 500;
    margin-top: 2em;
    margin-bottom: 1em;

    .user-id {
      font-family: "Courier New";
      font-stretch: condensed;
      position: absolute;
      bottom: 0.5em;
      right: 1em;
      font-weight: normal;
      font-size: small;
      color: lightgrey;
    }
  }
}

</style>
<script setup></script>