<template>
  <section class="container-fluid">

<!--  ceremony nav bar-->
    <ul ref="ceremonynavbar" class="nav fixed-top bg-light">
      <div v-for="(idx) in numCeremonies" :key="idx">
        <li class="nav-item" :class="{ active: ((numCeremonies+1)-idx === ceremony), 'decade-edge': isDecadeEdge((numCeremonies+1)-idx) }">
          <a @click="setCeremony((numCeremonies+1)-idx)" class="nav-link" aria-current="page" href="#">
            <img v-if="showNeedsReviewIndicator(((numCeremonies+1)-idx))" class="needs-review-badge" :src="`${publicPath}needs-review-badge.svg`"/>
            <div class="nav-ceremony-num">
              {{getCeremonyOrdinalText((numCeremonies+1)-idx)}}
            </div>
            <div class="nav-year">{{getYearOfCeremony((numCeremonies+1)-idx)}}</div>
          </a>
        </li>
      </div>
    </ul>

<!--  ceremony checklist -->

    <LoadingSpinner v-if="filmList.length === 0" class="text-secondary" loading_text="loading" style="margin-top: 100px;"></LoadingSpinner>

    <div v-else class="card mol-center-card-width ceremony-card text-bg-light">

      <div v-if="showCeremonyOverlay" class="floating-ceremony-header text-secondary text-bg-light fixed-top col-12">
        {{ceremonyOrdinalText}} Ceremony • {{ceremonyDateText}}
      </div>


      <div class="card-header ceremony-card-header text-secondary">
        <div class="ceremony-number-and-year">{{ceremonyOrdinalText}} Ceremony • {{ceremonyDateText}}</div>
        <div>Honoring achievements in</div>
        <div>{{ filmList.length }} films from {{ ceremonyYearsHonoredText(ceremony) }}</div>

        <div class="group-by-nomination">
          <div class="form-check form-switch group-by-nomination-switch text-secondary">
            <input class="form-check-input" type="checkbox" id="groupByNomination" v-model="showByCategory">
            <label class="form-check-label" for="groupByNomination">group by nomination</label>
          </div>
        </div>
      </div>


<!--      <div class="btn-group" role="group" aria-label="Basic radio toggle button group">-->
<!--        <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked>-->
<!--        <label class="btn btn-outline-secondary" for="btnradio1">Radio 1</label>-->

<!--        <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off">-->
<!--        <label class="btn btn-outline-secondary" for="btnradio2">Radio 2</label>-->
<!--      </div>-->

      <!--  films separated into category headers -->
      <div v-if="showByCategory" class="card-body card-body-category-list">
          <div v-for="(category, index) in categoryList" :key="category">
            <div class="text-secondary category-title" :class="{'first-category': index===0}">
              <img :src="`${publicPath}nom_icon-bs-secondary.svg`" alt="Logo" class="logo-image"><span v-html="categoryDisplayNameHtml(category)"></span>
            </div>

            <div v-for="(film, idxFilm) in filmsNominatedForCategory(category)" :key="`${category} - ${film.film_id}`">
              <hr v-if="idxFilm > 0" class="film-separator">
              <ChecklistFilm
                  class="film-card"
                  :film=film
                  :show_nominees_for_category="category"
                  :data-film-id=film.film_id
              >
              </ChecklistFilm>
            </div>
        </div>
      </div>

      <!--  just the films in a big list -->
      <div v-else class="card-body card-body-film-list">
        <div v-for="(film, index) in filmList" :key="film._id">
          <hr v-if="index > 0" class="film-separator">
          <ChecklistFilm class="film-card" :film=film :data-film-id=film._id></ChecklistFilm>
        </div>
      </div>
    </div>

  </section>

</template>


<script>

import axios from "axios";
//import FilmChecklist from "@/components/FilmChecklist.vue";
import {Film} from "@/film";
//import FilmWithCheckbox from "@/components/FilmWithCheckbox.vue";
import ChecklistFilm from "@/components/ChecklistFilm.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import {nextTick} from "vue";
import {userStore} from "@/userStore";
import analytics from "@/analytics";
//import {EventBus} from "@/eventBus";
//import CategoryAchievementRing from "@/components/CategoryAchievementRing.vue";

export default {
  name: "ChecklistPage",
  components: {LoadingSpinner, ChecklistFilm},
  props: ['init_ceremony'],
  data() {
    return {
      CEREMONY_REVIEWING_ENABLED: true,
      CEREMONY_NEEDS_REVIEW_INDICATOR_ENABLED: false,
      publicPath: process.env.BASE_URL,
      ceremony: null,
      numCeremonies: 0,
      filmList: [],
      filmsReviewed: new Set(),
      filmReviewTimers: new Map(),
      ceremonyOverview: null,
      showCeremonyOverlay: false,
      showByCategory: false
    }
  },
  async mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
    window.addEventListener("scroll", this.onScroll, true);

    axios.get('/api/ceremony/overview')

        .then((result) => {
          this.ceremonyOverview = result.data;

          // put most recent ceremonies first
          this.ceremonyOverview.sort((cer1, cer2) => {
            return (cer1.ceremony < cer2.ceremony) ? 1 : (cer1.ceremony > cer2.ceremony) ? -1 : 0;
          });

          this.numCeremonies = this.ceremonyOverview.length;

          // give it a tick to render before trying to scroll
          return nextTick();
        })

        .then(() => {
          const initial_ceremony = this.init_ceremony? Number(this.init_ceremony) : this.numCeremonies;

          const init_scroll_percent = 1.0 - (initial_ceremony / this.numCeremonies);
          const init_scroll_pixels = (this.$refs.ceremonynavbar.scrollWidth * init_scroll_percent) - 20;

          this.$refs.ceremonynavbar.scrollTo({
            top: 0,
            left: init_scroll_pixels,
            behavior: 'instant',
          });
          return this.setCeremony( ((initial_ceremony > 0) && (initial_ceremony < this.numCeremonies))? initial_ceremony : this.numCeremonies );
        })

        .catch((err) => {
          console.log(err);
        });
  },
  updated() {
    this.handleChecklistReviewed();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll, true)
  },
  computed: {
    ceremonyOrdinalText() {
      return Film.getCeremonyOrdinalText(this.ceremony);
    },
    ceremonyDateText() {
      const kMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const date = Film.getDateOfCeremony(this.ceremony);
      return `${kMonthNames[date.getUTCMonth()]} ${date.getUTCDate()} ${date.getUTCFullYear()}`;
    },
    categoryList() {
      return Film.getNominatedCategoriesForFilms(this.filmList);
    }
  },
  methods: {
    async setCeremony(_ceremony) {
      //console.log(`setCeremony(${_ceremony})`)

      if ( (_ceremony > 0) && (_ceremony <= this.numCeremonies) && (_ceremony != this.ceremony) ) {

        analytics.sendPage(this.$options.name, {
          ceremony: _ceremony
        });

        this.ceremony = _ceremony;
        this.filmList = [];

        axios.get(`/api/ceremony/${_ceremony}/films`)
            .then((response) => {
              this.filmList = response.data.map(f => new Film(f));
              this.filmsReviewed = new Set();
              Film.sortFilmsByPrioirity(this.filmList);

              if ( !this.CEREMONY_REVIEWING_ENABLED || userStore.isCeremonyReviewed(_ceremony) ) {
                //console.log(`ceremony ${_ceremony} reviewed`)
                this.filmsReviewed = new Set(this.filmList);

              } else {
                //console.log(`ceremony ${_ceremony} not reviewed`)
                this.filmsReviewed = new Set();
              }

            })

            .catch((err) => {
              console.log(err);
            });
      }
    },

    ceremonyYearsHonoredText(_ceremony) {
      return Film.getCeremonyYearText(_ceremony);
    },

    getCeremonyOrdinalText(_ceremony) {
      return Film.getCeremonyOrdinalText(_ceremony);
    },

    getYearOfCeremony(_ceremony) {
      const date = Film.getDateOfCeremony(_ceremony);
      return date.getUTCFullYear();
    },

    isDecadeEdge(_ceremony) {
      // don't designate ceremony edges before the 7th one
      if ( _ceremony < 7 ) {
        return false;
      }
      const ceremonyYear = this.getYearOfCeremony(_ceremony);
      return ( ceremonyYear === (Math.floor(ceremonyYear / 10) * 10) );
    },

    showNeedsReviewIndicator(_ceremony) {
      return (
          this.CEREMONY_NEEDS_REVIEW_INDICATOR_ENABLED &&
          this.CEREMONY_REVIEWING_ENABLED &&
          !userStore.isCeremonyReviewed(_ceremony)
      );
    },

    onScroll(/*e*/) {
      this.handleCeremonyOverlay();
      this.handleChecklistReviewed();
    },

    handleCeremonyOverlay() {
      //console.log(`window.top.scrollY = ${window.top.scrollY}`)
      this.showCeremonyOverlay = (window.top.scrollY > 100);
    },

    handleChecklistReviewed() {
      // early out if this ceremony has already been reviewed
      if ( !this.CEREMONY_REVIEWING_ENABLED || userStore.isCeremonyReviewed(this.ceremony) ) {
        return;
      }

      const filmCardElements = document.querySelectorAll(".film-card");
      filmCardElements.forEach((filmCardElement) => {
        if (this.isElementInViewport(filmCardElement)) {
          this.elementInView(filmCardElement);
        } else {
          this.elementNotInView(filmCardElement);
        }
      });
    },

    elementInView(_element) {
      const filmId = _element.getAttribute('data-film-id');
      if ( !this.filmsReviewed.has(filmId) && !this.filmReviewTimers.has(filmId) ) {
        //console.log(`>> ${filmId}`)
        this.filmReviewTimers.set(filmId, setTimeout(this.markFilmReviewed, 250, filmId));
        _element.classList.add('shimmer');
      }
    },

    elementNotInView(_element) {
      const filmId = _element.getAttribute('data-film-id');
      if ( this.filmReviewTimers.has(filmId) ) {
        //console.log(`<< ${filmId}`)
        clearTimeout( this.filmReviewTimers.get(filmId) );
        this.filmReviewTimers.delete(filmId);
        _element.classList.remove('shimmer');
      }
    },

    markFilmReviewed(_film_id) {

      if ( this.filmsReviewed.size === this.filmList.length ) {
        return;
      }

      //console.log(`***** ${_film_id}`)
      this.filmsReviewed.add(_film_id);

      if ( this.filmsReviewed.size === this.filmList.length ) {
        this.markCeremonyReviewed();
      }
    },

    markCeremonyReviewed() {
      //console.log(`********* all films reviewed ************`);
      //this.$refs.checklist.classList.add('shimmer');

      axios.post(`/api/user/ceremony-reviewed/${this.ceremony}`)
          .then((response) => {
            // If, for some reason, we're trying to mark a ceremony as reviewed
            //  that already IS marked reviewed, we return a 204 (no content).
            if ( response.status === 200 ) {
              userStore.setUser(response.data);

              // this.$gtag.event('ceremony_reviewed', {
              //   ceremony: this.ceremony
              // });
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },


    isElementInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          //rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },

    findFilmInFilmlist(_filmId) {
      return this.filmList.find(film => {
        return film._id === _filmId;
      });
    },

    filmsNominatedForCategory(_category) {
      return Film.filterFilmsNominatedForCategory(this.filmList, _category);
    },

    categoryDisplayNameHtml(_categoryName) {
      return Film.categoryDisplayNameHtml(_categoryName);
    },
  }
}
</script>


<style scoped>

* {
  --mol-checklist-navbar-min-height: 90px;
  --mol-checklist-navbar-max-height: 107px;
}

/* navbar */
ul.nav {
  /*
  opacity: 20%;
   */

  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1.5rem;
  padding-left: 0;
  margin-block: 0;
  overflow-x: auto;
  list-style: none;
  text-align: center;
  white-space: nowrap;

  margin-top: 60px;
  padding-top: 20px;
  z-index: 20;
  overflow-y: hidden;
  scroll-behavior: smooth;

  .nav-item {
    &.decade-edge {
      border-style: none solid none none;
      border-width: 1px;
    }
    line-height: 15px;
    position: relative;

    &.active {
      font-weight: 700;
    }

    .needs-review-badge {
      position: absolute;
      width: 1em;
      box-shadow: 0px 0.5px 1px lightgrey;
      /*
      rotate: -10deg;
      top: 2%;
      left: 0%;
       */
      rotate: 10deg;
      top: 0%;
      left: 75%;

    }
    .nav-ceremony-num {
    }
    .nav-year {
      font-size: small;
    }
    .active {
      font-weight: 700;
    }
    a {
      color: inherit;
    }
    .badge {
      font-size: 50%;
      vertical-align: top;
    }
  }
}

/* ceremony beneath navbar */
.ceremony-card {
  /*background-color: #f40852;*/

  font-size: large;

  margin-top: 100px;
  margin-bottom: 20px;


  /* floating header that appears when the ceremony year is scrolled out of view */
  .floating-ceremony-header {
    top: 170px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;

    font-weight: bold;
    z-index: 4;
    box-shadow: 0px 0px 20px 1px lightgrey;

    border-radius: 10px;
    border-color: lightgrey;
    border-style: solid;
    border-width: thin;
    padding: 5px 15px 5px 15px;

    /*background-color: RGB(248, 249, 250);*/
    animation: fadeIn 1s;
  }


  .ceremony-card-header {

    /*
    position: sticky;
    z-index: 10;
    top: 120px;
     */

    background-color: inherit;

    text-align: center;

    border-style: none none solid none;
    border-color: var(--bs-card-border-color);
    border-width: 1px;

    width: 90%;
    font-size: smaller;

    margin: 1em auto 0 auto;
    padding: 1em 0 0 0;

    .ceremony-number-and-year {
      font-weight: bold;
      font-size: large;
    }

    .group-by-nomination {

      font-size: xx-small;
      margin-top: 35px;

      width: fit-content;
      margin-left: auto;
      margin-right: auto;

      .group-by-nomination-switch {
        text-align: left;
        margin: 0;

        .form-check-label {
          &:hover {
            color: var(--bs-link-hover-color);
          }
        }

        .form-check-input {

          &:checked {
            /*
            background-color: var(--bs-card-border-color);
            color: white;
            border-color: inherit;
             */
          }
          &:focus {
            /*
            background-color: var(--bs-card-border-color);
            color: white;
            border-color: inherit;
            box-shadow: inherit;
             */
          }
        }


      }
    }
  }
}

.card-body {
  width: 100%;
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;

  hr {
    color: var(--bs-card-border-color);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.card-body-film-list {

}

.card-body-category-list {
  margin-top: 0;
  padding-top: 0;

  .category-title {
    text-align: center;
    font-size: x-large;
    img {
      height: 1.1em;
      vertical-align: text-bottom;
      margin-right: 0.1em;
    }
    padding: 1em 1em 0.5em 1em;
    margin-top: 1.5em;
    border-style: solid none none none;
    border-color: var(--bs-card-border-color);
    border-width: 1px;

    /* top category has a smaller top margin */
    &.first-category {
      padding-top: 1em;
      margin-top: 0;
      border-style: none;
    }

  }



}


</style>