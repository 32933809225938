<template>
  <div id="app">
    <NavBar @log-out-user="signOut"></NavBar>
    <router-view @user-updated="userUpdated"></router-view>
    <ToastNotification></ToastNotification>
  </div>
</template>

<script>

import NavBar from "@/components/NavBar.vue";
import axios from "axios";
import { userStore } from "@/userStore";
import ToastNotification from "@/components/toast notifications/ToastNotification.vue";

export default {
  name: 'App',
  components: {
    ToastNotification,
    NavBar
  },
  data() {
    return {
      userStore
    }
  },
  async created() {
    console.log('App.vue created()');
  },
  methods: {
    userUpdated(_user) {
      userStore.setUser(_user);
    },
    signOut() {
      axios
          .post("/api/auth/logout")
          .then((response) => {
            console.log("response:");
            console.log(response);
            userStore.setUser(null);
            this.$router.push({name: 'WelcomePage'});
          }, (error) => {
            console.log("error:");
            console.log(error);
            if ( error.response.data ) {
              alert(error.response.data);
            }
          })
    }
  }
}
</script>

<style>

  :root {
    --oscar-gold: #c7a662;

    --mol-color-gold: #e1bb28;
    --mol-color-win-icon: #b49823;
    --mol-navbar-height: 70px;
  }

  body {
    /* add some top-padding to the body to account for the navbar */
    padding-top: var(--mol-navbar-height);
  }
  #app {
    /* a little buffer from the bottom of the navbar */
    padding-top: 15px;
  }

  .admin-colors {
    color: crimson;
  }

  .mol-center-card-width {
    max-width: min(600px, calc(100vw - var(--bs-gutter-x)));
    margin-left: auto;
    margin-right: auto;
  }


</style>
