<template>
  <div class="achievement-ring"
       :class="{disabled: disabled}"
       :style="`width: ${size};`"
       data-bs-toggle="tooltip"
       data-bs-custom-class="achievement-ring-tooltip"
       data-bs-html="true"
       :title="tooltip_text"
  >

    <svg class="achievement-svg" viewBox="0 0 100 100" width="100%">
      <circle
          v-for="(segment, index) in total_segments"
          :key="index"
          :transform="`rotate(${index*(360.0/total_segments)})`"
          transform-origin='center'
          cx="50" cy="50"
          :r="radius"
          fill="transparent"
          :stroke="(index < filled_segments) ? filled_segment_color : empty_segment_color"
          :stroke-width="ringWidth"
          :stroke-dasharray="`${dashStrokeLength} ${dashSpaceLength}`"
          :stroke-dashoffset="strokeDashOffest"
      />

      <image
          v-if="center_img"
          :x="center-(centerImgSize*0.5)"
          :y="center-(centerImgSize*0.5)"
          :width="centerImgSize"
          :height="centerImgSize"
          :href="publicPath + center_img"
          class="center-image"
      />
    </svg>
    <div
        v-if="display_text"
        v-html="display_text"
        class="display-text"
        :class="{disabled: disabled}"
        :style="`width: ${size};`"
    >
    </div>
  </div>
</template>


<script>
import {Tooltip} from "bootstrap";

export default {
  name: "AchievementRing",
  components: {},
  props: ['display_text', 'tooltip_text', 'total_segments', 'filled_segments', 'size', 'disabled', 'center_img'],
  data() {
    return {
      empty_segment_color: '#efefef',
      filled_segment_color: '#e1bb28',
      publicPath: process.env.BASE_URL
    }
  },
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
  },
  computed: {

    ringWidth() {
      //return (this.size * 0.12);
      return (100 * 0.12);
    },
    center() {
      //return (this.size / 2.0);
      return (100 / 2.0);
    },
    diameter() {
      //return (this.size - this.ringWidth);
      return (100 - this.ringWidth);
    },
    innerDiameter() {
      //return (this.diameter - this.ringWidth);
      return (100 - this.ringWidth);
    },
    radius() {
      return (this.diameter / 2.0);
    },
    circumference() {
      return (this.diameter * Math.PI);
    },
    dashStrokeBorderSize() {
      const maxBorderSize = this.circumference / 180.0;
      const proportionallySizedBorder = this.circumference / (this.total_segments * 5.0);
      return ( (this.total_segments === 1)? 0 : Math.min(maxBorderSize, proportionallySizedBorder) );
    },
    dashStrokeLength() {
      return (this.circumference / this.total_segments) - this.dashStrokeBorderSize;
    },
    dashSpaceLength() {
      return (this.circumference - this.dashStrokeLength);
    },
    strokeDashOffest() {
      return (this.circumference/4);
    },
    centerImgSize() {
      const maxBorderSize = this.circumference / 180.0;
      return this.innerDiameter - (maxBorderSize * 2.0);
    }
  }
}
</script>

<style>
.achievement-ring-tooltip {

  /* OVERRIDABLE BOOTSTRAP VARIABLES
  --bs-tooltip-zindex
  --bs-tooltip-max-width
  --bs-tooltip-padding-x
  --bs-tooltip-padding-y
  --bs-tooltip-margin
  --bs-tooltip-color
  --bs-tooltip-bg
  --bs-tooltip-border-radius
  --bs-tooltip-opacity
  --bs-tooltip-arrow-width
  --bs-tooltip-arrow-height
   */

  --bs-tooltip-bg: #b49823!important;
  --bs-tooltip-color: var(--bs-body-bg)!important;
  --bs-tooltip-opacity: 1.0!important;
  --bs-tooltip-arrow-height: 1em!important;
  font-size: x-small!important;
}

</style>

<style scoped>
.achievement-ring {
  vertical-align: bottom;

  display: inline-block;
  margin: 2px;
  text-align: center;

  &.disabled {
    opacity: 50%;
  }

  /*
  border: solid;
  border-color: lightpink;
  border-width: 1px;

   */

  .achievement-svg {
    /*
    border: solid;
    border-color: lightblue;
    border-width: 0.01px;
     */
  }

  font-size: min(7px, 1.5vi);
  text-align: center;
  line-height: 1.6cap;

  margin: 0 2px 8cap 2px;

}


.display-text {

  /*
  border: solid;
  border-color: #01b4e4;
  border-width: 0.1px;
 */

  position: absolute;
  display: block;

  /*
  font-family: "proxima-nova-condensed", sans-serif;
  font-weight: 200;
  font-style: normal;
   */



  /*font-weight: bold;*/

  /*
  margin-top: 0.5em;
  margin-bottom: 0;
   */

  margin-left: 0;
  margin-right: 0;

  &.disabled {
    opacity: 30%;
  }
}

.center-image {
  clip-path: circle(50% at 50% 50%);
  svg {
    .cls-1 {
      fill: #01b4e4!important;
    }

  }
}
</style>