<template>
  <div class="card">

    <div class="card-header">
      <slot name="header"></slot>
      <div v-if="showExpanded" class="btn btn-sm btn-show-more" type="button" @click="showExpanded = !showExpanded">
        <i :class="showExpanded? 'fa fa-caret-up' : 'fa fa-caret-down'" aria-hidden="true"></i>
        <span v-text="showExpanded? ' show less ' : ' show more '" aria-hidden="true"></span>
      </div>
      <hr>
    </div>

    <div class="card-body" :class="showExpanded? 'expanded' : 'not-expanded'">
      <slot name="body"></slot>
    </div>

    <div class="card-footer">
      <slot name="footer"></slot>
      <div class="btn btn-sm btn-show-more" type="button" @click="showExpanded = !showExpanded">
        <i :class="showExpanded? 'fa fa-caret-up' : 'fa fa-caret-down'" aria-hidden="true"></i>
        <span v-text="showExpanded? ' show less ' : ' show more '" aria-hidden="true"></span>
      </div>
    </div>

  </div>
</template>



<script>

export default {
  name: "ExpandableCard",
  props: [],
  emits: ['show-more', 'show-less'],

  data() {
    return {
      showExpanded: false,
      expandedIndex: 8
    }
  },

  watch: {
    showExpanded(newValue) {
      this.$emit(newValue ? 'show-more' : 'show-less');
    }
  },
}

</script>


<style scoped>

  .card {

    .btn-show-more {
      position: absolute;
      right: 1em;

      font-size: small;
      color: var(--bs-secondary);
      opacity: 80%;
    }

    .card-header {
      padding-top: 16px;
      padding-bottom: 0;
      background-color: inherit;
      border: none;

      .btn-show-more {
        background-color: inherit;
        top: 0;
      }

      hr {
        margin-bottom: 0;
      }
    }

    .card-body {
      position: relative;
      padding-bottom: 0;

      &.expanded {
        max-height: auto;
      }

      &.not-expanded {
        overflow-y: hidden;
        max-height: 150px;
      }
    }

    .card-footer {
      min-height: 40px;
      background-color: inherit;
      border-top: none;

      .btn-show-more {
        bottom: 5px;
      }

    }
  }



</style>