<template>
  <section class="container-fluid">

    <!--        top title -->
    <h1 class="text-center logo-themed-header">
      <LogoSvg class="logo-icon" color="#e1bb28"></LogoSvg>
      <span class="text-my-oscar-list">MY<span class="text-oscar">OSCAR</span>LIST</span>
    </h1>

    <!--        about the list -->
    <div class="mol-center-card-width feature-section feature-section-about-the-list">
      <h4 class="feature-section-title text-my-oscar-list"><img :src="`${publicPath}win_icon.svg`" alt="Logo" class="logo-image"><span class="text-oscar">ABOUT</span>THE<span class="text-oscar">LIST</span></h4>
      <p>
        One Friday evening many years ago, my friend and I found ourselves in a video rental store, staring at the new-releases wall, looking for a good weekend movie.
      </p>
      <p>
        In a moment of insight, it occurred to us that the rest of the shelves were filled with movies we hadn't seen; many we'd probably enjoy more than any new release.
      </p>
      <p class="insight-box">
        We'd been watching<br><em>new movies regardless of quality</em>,<br>when we should've been watching<br><em>quality movies regardless of age.</em>
      </p>
      <p>
        The first list was a printed paper packet. It included every film ever nominated for Best Picture; and for good measure we added films nominated for Best Director, as well as the four Acting categories. Each film had two checkboxes: one for each of us.
      </p>
      <p>
        The list changed the way we watched movies. It began to feel meaningful. And we made a game of completing categories: we started watching films we never would have watched before just to say "we've seen all the Best Picture nominees for 1969".
      </p>
      <p class="insight-box">
        Without any deliberate or noble intention, our checklist compelled us to watch movies exploring new ideas and unconsidered points of view.
      </p>
      <p>
        MY<b>OSCAR</b>LIST.COM was created to share this checklist. We hope you enjoy tracking your progress, sharing with friends, and challenging yourself to watch great films you might otherwise never have seen.
      </p>
      <p>
        <router-link class="login-link" to="/log-in">Log in</router-link> to get started now.
      </p>
    </div>

    <!--        checklist -->
    <div class="mol-center-card-width feature-section feature-section-checklist">
      <h4 class="feature-section-title text-my-oscar-list"><img :src="`${publicPath}win_icon.svg`" alt="Logo" class="logo-image">MARK<span class="text-oscar">YOUR</span>FILMS</h4>
      <p>
        Access your personal checklist of every film ever nominated for an Academy Award.
      </p>
      <img class="checklist-image" :src="`${publicPath}about/checklist.png`">
      <div>
        <p><img class="checkmark-image" :src="`${publicPath}about/checkmark.png`"> Check the films you've seen.</p>
        <p><img class="checkmark-image" :src="`${publicPath}about/bookmark.png`"> Bookmark the ones you want to watch next.</p>
        <p><img class="checkmark-image" :src="`${publicPath}about/friend.png`"> See which films your friends have seen.</p>
      </div>
      <p>
        <router-link class="login-link" to="/log-in">Log in</router-link> to get started now.
      </p>
    </div>


    <!--        dashboard -->
    <div class="mol-center-card-width feature-section feature-section-dashboard">
      <h4 class="feature-section-title text-my-oscar-list"><img :src="`${publicPath}win_icon.svg`" alt="Logo" class="logo-image">TRACK<span class="text-oscar">YOUR</span>PROGRESS</h4>
      <img class="dashboard-image" :src="`${publicPath}about/dashboard.png`">
      <p>
        View your dashboard, featuring unique fitness-ring-style progression meters and achievements.
      </p>
      <p>
        <router-link class="login-link" to="/log-in">Log in</router-link> to get started now.
      </p>

    </div>


    <!--        detail modals -->
    <div class="mol-center-card-width feature-section">
      <h4 class="feature-section-title text-my-oscar-list"><img :src="`${publicPath}win_icon.svg`" alt="Logo" class="logo-image">GET<span class="text-oscar">THE</span>DETAILS</h4>
      <div class="row">
        <div class="col-6">
          <img class="film-details-image" :src="`${publicPath}about/film-details.png`">
          <div style="margin-top: 1em;">
            <p><img class="checkmark-image" :src="`${publicPath}win_icon.svg`"> Explore each film's nominations and wins.</p>
            <p>Follow a nomination's link to see which other films were competing in that category.</p>
          </div>
        </div>
        <div class="col-6">
          <p>Dive into the details of each film, including a short description, genres, running time, and if this film is in a foreign language.</p>
          <img class="film-details-image" :src="`${publicPath}about/category-details.png`">
        </div>
      </div>

      <p style="margin-top: 2em;">
        <router-link class="login-link" to="/log-in">Log in</router-link> to get started now.
      </p>
    </div>


    <!--        viewing options -->
    <div class="mol-center-card-width feature-section">
      <h4 class="feature-section-title text-my-oscar-list"><img :src="`${publicPath}win_icon.svg`" alt="Logo" class="logo-image">FIND<span class="text-oscar">YOUR</span>FILMS</h4>
      <div class="row">
        <div class="col-6">
          <img class="film-details-image" :src="`${publicPath}about/viewing-options.png`">
        </div>

        <div class="col-6">
          <div style="margin-top: 1em;">
            <p>Find nominated films in your cable schedule and streaming services.</p>
            <div class="row find-your-films-logos">
              <div v-for="(imgName) in streamingServiceLogoImages" :key="imgName" class="col-4 find-your-films-logo">
                <img class="streaming-service-logo-image" :src="`${publicPath}logos/content-providers/large-logos/${imgName}`">
              </div>
            </div>
          </div>
        </div>
      </div>

      <p style="margin-top: 2em;">
        <router-link class="login-link" to="/log-in">Log in</router-link> to get started now.
      </p>
    </div>

    <!--        about the data -->
    <div class="mol-center-card-width feature-section">
      <h4 class="feature-section-title text-my-oscar-list"><img :src="`${publicPath}win_icon.svg`" alt="Logo" class="logo-image"><span class="text-oscar">ABOUT</span>THE<span class="text-oscar">DATA</span></h4>

      <!--        Academy Award Database -->
      <div class="data-provider">
        <img :src="`${publicPath}logos/academy/OscarLogo.svg`" class="data-provider-logo">
        <p>
          All nomination data, including film names, nomination categories, nominees and winners, was collected directly from <a href="https://awardsdatabase.oscars.org/" title="Academy Awards Database" target="blank">The Official Academy Awards Database<sup><i class="fa fa-external-link"></i></sup></a>.
        </p>
      </div>

      <!--        The Movie DB -->
      <div class="data-provider">
        <img :src="`${publicPath}logos/tmdb/tmdb_logo_blue_square_2.svg`" class="data-provider-logo">
        <p>
          Film details, including genres, foreign languages, plot overviews, movie posters and streaming options, come directly from <a href="https://www.themoviedb.org/" title="TMDB" target="blank">The Movie DB<sup><i class="fa fa-external-link"></i></sup></a>.
        </p>
        <p>This product uses the TMDB API but is not endorsed or certified by TMDB.</p>
      </div>

      <!--        Gracenote -->
      <div class="data-provider">
        <img :src="`${publicPath}logos/gracenote/gracenote-logo.svg`" class="data-provider-logo">
        <p>
          Information about TV network schedules and local theater listings comes directly from <a href="https://gracenote.com/" title="Gracenote" target="blank">Gracenote<sup><i class="fa fa-external-link"></i></sup></a>.
        </p>
      </div>

      <!--        MongoDB -->
      <div class="data-provider">
        <img :src="`${publicPath}logos/mongo/MongoDB_Fores-Green.svg`" class="data-provider-logo">
        <p>
          We use <a href="https://www.mongodb.com/" title="MongoDB" target="blank">Mongo DB<sup><i class="fa fa-external-link"></i></sup></a> as our secure storage solution for all gathered film and user data.
        </p>
      </div>

      <div class="data-provider">
        <p>
          Your data is never shared with or sold to any other party.
        </p>
      </div>

      <p style="margin-top: 2em;">
        <router-link class="login-link" to="/log-in">Log in</router-link> to get started now.
      </p>
    </div>

    <div class="mol-center-card-width feature-section">
      <h4 class="feature-section-title text-my-oscar-list"><img :src="`${publicPath}win_icon.svg`" alt="Logo" class="logo-image">START<span class="text-oscar">YOUR</span>LIST</h4>
      <div style="text-align: center;">
        <router-link class="btn btn-primary btn-login" to="/log-in">
          log in
        </router-link>
        to get started now.
      </div>

    </div>

  </section> <!-- container -->
</template>


<script>



  import LogoSvg from "@/components/icons/LogoSvg.vue";

  export default {
    name: "WelcomePage",
    components: {
      LogoSvg
    },
    computed: {
      publicPath() {
        return process.env.BASE_URL;
      },
      streamingServiceLogoImages() {
        return [
          "tcm-logo.svg",
          "amc-logo.svg",
          "hbo-logo.svg",
          "amazon-prime-video-logo.svg",
          "apple-tv-plus-logo.svg",
          "disney-plus-logo.svg",
          "hulu-logo.svg",
          "kanopy-logo.svg",
          "max-logo.svg",
          "netflix-logo.svg",
          "paramount-plus-logo.svg",
          "peacock-logo.svg",
          //"shorts-tv-logo.svg"
        ];
      }
    },
    methods: {
    }
  }

</script>


<style scoped>

.logo-themed-header {
  color: var(--mol-color-gold);
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: xxx-large;

  svg {
    height: 1.5cap;
    margin-right: 0.1em;
    margin-top: -0.17cap;
  }
}

.login-link {
  text-decoration: none;
}

.feature-section {

  color: var(--mol-color-win-icon);


  .feature-section-title {
    color: var(--mol-color-win-icon);
    text-align: center;
    margin-top: 0.5em;
    margin-bottom: 1em;
  }

  background-color: white;

  p {
    text-align: center;
  }
  overflow: hidden;
  margin-bottom: 40px;

  border: solid;
  border-width: 3px;
  border-color: var(--mol-color-gold);
  border-radius: 0.375rem;
  padding: 20px;

  &.feature-section-checklist {
  }

  &.feature-section-dashboard {
  }

  .data-provider {
    display: inline-block;

    .data-provider-logo {
      float: left;
      padding: 1em;
      padding-left: 0;
      max-height: 7em;
      max-width: 40%;
    }

    a {
      color: inherit;
      text-decoration: inherit;
      font-style: italic;
    }
    .fa-external-link {
      margin-left: 0.2em;
    }
  }
  .data-provider:nth-child(odd) {
    .data-provider-logo {
      float: right;
      padding: 1em;
      padding-right: 0;
    }
  }
}

/*
p {
  font-size: small;
}
 */


.checklist-image {
  display: block;
  width: 100%;
  max-width: 300px;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
}

.dashboard-image {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  margin-bottom: 1em;
}

.film-details-image {
  width: 100%;
}

.checkmark-image {
  height: 1.5cap;
}

.inline-logo-image {
  height: 1em;
}

.streaming-service-logo-image {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}


.text-my-oscar-list {
  font-weight: 300;

  .text-oscar {
    font-weight: 600;
    padding-left: 0.05em;
    padding-right: 0.05em;
  }

  img {
    height: 1em;
    margin-right: 0.1em;
    margin-top: -0.2em;
  }
}

.find-your-films-logos {
  margin-top: 2em;

  .find-your-films-logo{
    height: 40px;
  }

}


</style>