// import { AnalyticsBrowser } from '@segment/analytics-next'
// const segment = AnalyticsBrowser.load({ writeKey: 'zfRU8PTTu1ebxKOFLYBasPwLH472n4mW' })

import mixpanel from 'mixpanel-browser'
mixpanel.init('4448d86056d3c54311902f0488046645', {
    autocapture: false,
    persistence: 'localStorage',    // set persistence to local storage
    //debug: true,                    // enable debug log in console
});

export default {

    setUser(_user) {
        if ( _user ) {
            // segment.identify(_user._id, {
            //     name: _user.username,
            // });

            mixpanel.identify(_user._id);
            // mixpanel.people.set({
            //     'Name': _user.username
            // });

        } else {
            //segment.identify(); // is this how we set anonymous??
            mixpanel.reset();
        }
    },

    sendPage(_pageName, _properties) {
        //segment.page(_pageName, _properties);
        mixpanel.track_pageview({
            "page": _pageName,
            ..._properties
        });

    },

    sendEvent(_eventName, _properties) {
        //segment.track(_eventName, _properties);
        mixpanel.track(_eventName, _properties);
    },

    sendAchievement(_achievementName, _properties) {

        // segment.track('Achievement', {
        //     achievement: _achievementName,
        //     ..._properties
        // });

        this.sendEvent('Achievement', {
            achievement: _achievementName,
            ..._properties
        });
    }

}