<template>
  <div class="container-fluid">

    <!--        top title -->
    <h1 class="text-center logo-themed-header">
      <LogoSvg class="logo-icon" color="#e1bb28"></LogoSvg>
      <span class="text-my-oscar-list">MY<span class="text-oscar">OSCAR</span>LIST</span>
    </h1>

    <!--        about the list -->
    <div class="mol-center-card-width feature-section feature-section-about-the-list">
      <h4 class="feature-section-title text-my-oscar-list"><img :src="`${publicPath}win_icon.svg`" alt="Logo" class="logo-image"><span class="text-oscar">ABOUT</span>THE<span class="text-oscar">LIST</span></h4>
      <p>
        One Friday evening many years ago, my friend and I found ourselves in a video rental store, staring at the new-releases wall, looking for a good weekend movie.
      </p>
      <p>
        In a moment of insight, it occurred to us that the rest of the shelves were filled with movies we hadn't seen; many we'd probably enjoy more than any new release.
      </p>
      <p class="insight-box">
        We'd been watching<br><em>new movies regardless of quality</em>,<br>when we should've been watching<br><em>quality movies regardless of age.</em>
      </p>
      <p>
        The first list was a printed paper packet. It included every film ever nominated for Best Picture; and for good measure we added films nominated for Best Director, as well as the four Acting categories. Each film had two checkboxes: one for each of us.
      </p>
      <p>
        The list changed the way we watched movies. It began to feel meaningful. And we made a game of completing categories: we started watching films we never would have watched before just to say "we've seen all the Best Picture nominees for 1969".
      </p>
      <p class="insight-box">
        Without any deliberate or noble intention, our checklist compelled us to watch movies exploring new ideas and unconsidered points of view.
      </p>
      <p>
        MY<b>OSCAR</b>LIST.COM was created to share this checklist. We hope you enjoy tracking your progress, sharing with friends, and challenging yourself to watch great films you might otherwise never have seen.
      </p>
    </div>

    <!--        about the data -->
    <div class="mol-center-card-width feature-section">
      <h4 class="feature-section-title text-my-oscar-list"><img :src="`${publicPath}win_icon.svg`" alt="Logo" class="logo-image"><span class="text-oscar">ABOUT</span>THE<span class="text-oscar">DATA</span></h4>

      <!--        Academy Award Database -->
      <div class="data-provider">
        <img :src="`${publicPath}logos/academy/OscarLogo.svg`" class="data-provider-logo">
        <p>
          All nomination data, including film names, nomination categories, nominees and winners, was collected directly from <a href="https://awardsdatabase.oscars.org/" title="Academy Awards Database" target="blank">The Official Academy Awards Database<sup><i class="fa fa-external-link"></i></sup></a>.
        </p>
      </div>

      <!--        The Movie DB -->
      <div class="data-provider">
        <img :src="`${publicPath}logos/tmdb/tmdb_logo_blue_square_2.svg`" class="data-provider-logo">
        <p>
          Most film details, including genres, foreign languages, plot overviews, movie posters and streaming services, come directly from <a href="https://www.themoviedb.org/" title="TMDB" target="blank">The Movie DB<sup><i class="fa fa-external-link"></i></sup></a>.
        </p>
        <p>This product uses the TMDB API but is not endorsed or certified by TMDB.</p>
      </div>

      <!--        Gracenote -->
      <div class="data-provider">
        <img :src="`${publicPath}logos/gracenote/gracenote-logo.svg`" class="data-provider-logo">
        <p>
          Information about TV network schedules and local theater listings comes directly from <a href="https://gracenote.com/" title="Gracenote" target="blank">Gracenote<sup><i class="fa fa-external-link"></i></sup></a>.
        </p>
      </div>

      <!--        MongoDB -->
      <div class="data-provider">
        <img :src="`${publicPath}logos/mongo/MongoDB_Fores-Green.svg`" class="data-provider-logo">
        <p>
          We use <a href="https://www.mongodb.com/" title="MongoDB" target="blank">Mongo DB<sup><i class="fa fa-external-link"></i></sup></a> as our secure storage solution for all gathered film and user data.
        </p>
      </div>

      <div class="data-provider">
        <p>
          Your data is never shared with or sold to any other party.
        </p>
      </div>
    </div>

  </div> <!-- container -->
</template>


<script>



import LogoSvg from "@/components/icons/LogoSvg.vue";

export default {
  name: "AboutPage",
  components: {
    LogoSvg
  },
  computed: {
    publicPath() {
      return process.env.BASE_URL;
    },
    streamingServiceLogoImages() {
      return [
        "tcm-logo.svg",
        "amc-logo.svg",
        "hbo-logo.svg",
        "amazon-prime-video-logo.svg",
        "apple-tv-plus-logo.svg",
        "disney-plus-logo.svg",
        "hulu-logo.svg",
        "kanopy-logo.svg",
        "max-logo.svg",
        "netflix-logo.svg",
        "paramount-plus-logo.svg",
        "peacock-logo.svg",
        //"shorts-tv-logo.svg"
      ];
    }
  },
  methods: {
  }
}

</script>


<style scoped>

.logo-themed-header {
  color: var(--mol-color-gold);
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: xxx-large;

  svg {
    height: 1.5cap;
    margin-right: 0.1em;
    margin-top: -0.17cap;
  }
}

.feature-section {
  color: var(--mol-color-win-icon);
  background-color: white;
  overflow: hidden;
  margin-bottom: 40px;
  padding: 2em;

  border: solid;
  border-width: 3px;
  border-color: var(--mol-color-gold);
  border-radius: 0.375rem;

  .feature-section-title {
    color: var(--mol-color-win-icon);
    text-align: center;
    margin-bottom: 1em;
  }

  .insight-box {
    font-weight: 500;
    font-size: smaller;
    text-align: center;
    border: solid;
    border-width: thin;
    border-color: color-mix(in srgb, var(--mol-color-gold), transparent 50%);
    padding: 1em;

    margin-right: auto;
    margin-left: auto;
  }

  .feature-section-about-the-list {
    text-align: center;
  }


  .data-provider {
    display: inline-block;

    .data-provider-logo {
      float: left;
      padding: 1em;
      padding-left: 0;
      max-height: 7em;
      max-width: 40%;
    }

    a {
      color: inherit;
      text-decoration: inherit;
      font-style: italic;
    }
    .fa-external-link {
      margin-left: 0.2em;
    }
  }
  .data-provider:nth-child(odd) {
    .data-provider-logo {
      float: right;
      padding: 1em;
      padding-right: 0;
    }
  }
}



.text-my-oscar-list {
  font-weight: 300;

  .text-oscar {
    font-weight: 600;
    padding-left: 0.05em;
    padding-right: 0.05em;
  }

  img {
    height: 1em;
    margin-right: 0.1em;
    margin-top: -0.2em;
  }
}

</style>