<template>
  <svg :width="width" :height="height" ref="graphic">

<!--    all the films viewed -->
    <line
        v-for="(ceremony) in filmsViewedDigest"
        :key="ceremony.ceremony"
        :x1="`${(ceremony.ceremony*100)/filmsViewedDigest.length}%`"
        :x2="`${(ceremony.ceremony*100)/filmsViewedDigest.length}%`"
        y1="100%"
        :y2="`${100-ceremony.percentViewed}%`"
        :stroke="color"
        :stroke-width="`${barWidthPercent/filmsViewedDigest.length}%`"
        opacity="50%"
    />

<!--    the WINNING films viewed -->
    <line
        v-for="(ceremony, index) in filmsViewedDigest"
        :key="ceremony.ceremony"
        :x1="`${(index*100)/filmsViewedDigest.length}%`"
        :x2="`${(index*100)/filmsViewedDigest.length}%`"
        y1="100%"
        :y2="`${100-ceremony.percentViewedWinners}%`"
        :stroke="color"
        :stroke-width="`${barWidthPercent/filmsViewedDigest.length}%`"
    />

  </svg>
</template>

<script>
import axios from "axios";

export default {
  name: "ProfileFilmsViewedGraphic",
  props: ['user', 'width', 'height', 'color'],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      ceremonyOverview: [],
      filmsViewedDigest: []
    }
  },
  computed: {
    barWidthPercent() {
      return 70;
    }
  },

  watch: {
    user(/*newValue, oldValue*/) {
      this.updateDigest();
    },
  },

  async created() {
    this.initDigest();
  },

  methods: {

    async initDigest() {

      this.ceremonyOverview = [];
      this.filmsViewedDigest = [];

      axios.get('/api/ceremony/overview')
          .then((result) => {
            this.ceremonyOverview = result.data;

            // put most recent ceremonies last
            this.ceremonyOverview.sort((cer1, cer2) => {
              return (cer1.ceremony > cer2.ceremony) ? 1 : (cer1.ceremony < cer2.ceremony) ? -1 : 0;
            });

            this.updateDigest();


            //console.log(this.ceremonyOverview);
          })

          .catch((err) => {
            console.log(err);
          });
    },

    updateDigest() {

      this.$refs.graphic.classList.remove('fade-in');

      this.filmsViewedDigest = this.ceremonyOverview.map((cer) => {

        let filmList = cer.nomination_summary.map((nom) => {
          return nom.films;
        }).flat(2);
        let winningFilmList = filmList.filter((film) => {
          return film.won;
        });

        filmList = filmList.map(film => film.film_id);
        winningFilmList = winningFilmList.map(film => film.film_id);

        filmList = filmList.filter((flFilter, index) => {
          const foundIndex = filmList.findIndex((flFind) => {
            return (flFind === flFilter);
          });
          return index === foundIndex;
        });

        winningFilmList = winningFilmList.filter((flFilter, index) => {
          const foundIndex = winningFilmList.findIndex((flFind) => {
            return (flFind === flFilter);
          });
          return index === foundIndex;
        });

        const numViewed = filmList.filter(filmId => this.user.viewed.includes(filmId)).length;
        const numWinnersViewed = winningFilmList.filter(filmId => this.user.viewed.includes(filmId)).length;

        return {
          ceremony: cer.ceremony,
          numFilms: filmList.length,
          numViewed: numViewed,
          percentViewed: (numViewed * 100) / filmList.length,
          numWinnersViewed: numWinnersViewed,
          percentViewedWinners: (numWinnersViewed * 100) / filmList.length,
        };
      });

      this.$refs.graphic.classList.add('fade-in');
    }
  }
}
</script>

<style scoped>

svg {
  visibility: hidden;

  &.fade-in {
    visibility: visible;
    animation: fadeIn 0.5s;
  }
}

@keyframes fadeIn {
  0% {opacity: 0%;}
  100% {opacity: 100%;}
}

</style>