<template>

<!--  <div class="banner-image">-->
<!--    &lt;!&ndash;    ad for the 97th ceremony &ndash;&gt;-->
<!--    <a href="https://abc.com/shows/oscars" target='blank'>-->
<!--      <img :src="`${this.publicPath}promos/2025OscarsOnABC.png`">-->
<!--    </a>-->
<!--  </div>-->

  <div class="container">
    <div v-if="user">
      <LoadingSpinner v-if="!ceremonyOverview" class="text-secondary" loading_text="loading" style="margin-top: 50px;"></LoadingSpinner>

      <!-- each ceremony -->
      <CeremonyCard v-else v-for="(ceremony_info, index) in ceremonyOverview" :key="index" :ceremony_info="ceremony_info">
      </CeremonyCard>

    </div>
  </div>
</template>

<script>


import axios from "axios";
import {userStore} from "@/userStore";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import CeremonyCard from "@/components/dashboard/CeremonyCard.vue";

export default {
  name: "DashboardPage",
  components: {
    CeremonyCard,
    LoadingSpinner
  },
  computed: {
    user() {
      return userStore.user;
    }
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      ceremonyOverview: null
    }
  },
  async created() {
    axios.get('/api/ceremony/overview')
        .then((result) => {
          this.ceremonyOverview = result.data;

          // put most recent ceremonies first
          this.ceremonyOverview.sort((cer1, cer2) => {
            return (cer1.ceremony < cer2.ceremony)? 1 : (cer1.ceremony > cer2.ceremony)? -1 : 0;
          });
          //console.log(this.ceremonyOverview);
        })

        .catch((err) => {
          console.log(err);
        });
  },
  methods: {

    firstHalfOfArray(_array) {
      const half = Math.ceil(_array.length / 2)
      return _array.slice(0, half);
    },

    secondHalfOfArray(_array) {
      const half = Math.ceil(_array.length / 2)
      return _array.slice(half);
    },

  }
}
</script>


<style scoped>

.banner-image {

  margin-right: auto;
  margin-left: auto;
  margin-top: -10px;
  padding-bottom: 30px;
  max-width: 500px;
  img {
    width: 100%;
  }
}

.upcoming-ceremony {

  text-align: center;
  font-size: small;
  color: var(--bs-secondary);

  .upcoming-ceremony-header {
    text-align: center;
    margin-bottom: 1em;
    background-color: transparent;
    /*border: none;*/
  }

  a {
    display: block;
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;

    color: var(--bs-link-hover-color);

    .fa-external-link {
      color: var(--bs-link-hover-color);
      vertical-align: super;
    }
  }
  a:hover {
    color: var(--bs-link-hover-color);
  }

  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: auto;
  margin-left: auto;
  max-width: 450px;

  padding: 15px 5px 5px 5px;

  border-style: solid none solid none;
  border-color: lightgrey;
  border-width: 1px;
}

</style>